import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { refreshPaySignInWithFee } from '../../../networks/paysystem.service';
import { isSignedAtom, loginInfoAtom, removeStorageToken, removeStorageUserInfo, userInfoAtom } from '../../../networks/store/member';
import {
  paySystemMemberAtom
} from '../../../networks/store/paysystem';
import {
  ProductMembershipType,
  SubscribeMembershipType,
} from '../../../types_new/main/landing/types';
import { IMemberLoginData } from '../../../networks/dto/IMemberDto';

type Props = {
  membership: ProductMembershipType | SubscribeMembershipType;
  isSubscription?: boolean;
  activeIdx: number;
  idx: number;
};
export default function MembershipCard({
  membership: { membership, description, pay, credit, limit },
  isSubscription,
  activeIdx,
  idx,
}: Props) {
  const isLoggedIn = useRecoilValue(isSignedAtom);
  const isActive = activeIdx === idx;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const setPaySystemMember = useSetRecoilState(paySystemMemberAtom);
  const userInfo = useRecoilValue(userInfoAtom);
  const setIsLoggedIn = useSetRecoilState(isSignedAtom);
  const setLoginInfo = useSetRecoilState(loginInfoAtom);
  
  /**
   * 로그아웃 처리
   */
  const doLogOut = () => {
    removeStorageToken();
    removeStorageUserInfo();
    setIsLoggedIn(false);
    setLoginInfo({} as IMemberLoginData);
    navigate('/');
  };

  /**
   * 결제시스템 token refresh
   * @param type 
   * @param payInfo 
   * @returns 
   */
  const refreshPaySignInCB = (type, payInfo) => {
    if (!payInfo || !type) {
      console.log('[handleOnPay] refreshPaySignIn error res:' + payInfo);
      alert('다시 로그인 하십시요.');
      doLogOut();
      return;
    }
    console.log('[handleOnPay] refreshPaySignIn payInfo:' + JSON.stringify(payInfo));
    setPaySystemMember(payInfo);
    const payToken = payInfo?.paySystemToken;
    if (payToken) {
      let payUrl = '';
      // let productCd = '';
      // let redirectParam = '';

      if (process.env.REACT_APP_SERVICE_MODE === 'production') {
        payUrl = `${process.env.REACT_APP_PAYGW_RELSERVER_URL}`;
      } else {
        payUrl = `${process.env.REACT_APP_PAYGW_DEVSERVER_URL}`;
      }

      const productMap = {
        'Basic': { productCd: 'CARD_OM_STUDIO_2411_BASIC', redirectParam: '/home/mypage/creditStudioNew' },
        'Professional': { productCd: 'CARD_OM_STUDIO_2411_PRO', redirectParam: '/home/mypage/creditStudioNew' },
        'Business': { productCd: 'CARD_OM_STUDIO_2411_BIZ', redirectParam: '/home/mypage/creditStudioNew' },
        'Basic Plus': { productCd: 'CARD_SM_STUDIO_2411_BASIC', redirectParam: '/home/mypage/creditStudioPlanNew' },
        'Professional Plus': { productCd: 'CARD_SM_STUDIO_2411_PRO', redirectParam: '/home/mypage/creditStudioPlanNew' },
        'Business Plus': { productCd: 'CARD_SM_STUDIO_2411_BIZ', redirectParam: '/home/mypage/creditStudioPlanNew' },
      };

      const { productCd, redirectParam } = productMap[type] || {};
      if (productCd && redirectParam) {
        const tUrl = `${payUrl}/work/TokenLogin?token=${payToken}&redirect=${redirectParam}?servicePlan=${productCd}&type=B13`;
        window.open(tUrl, '결제팝업', 'width=760px,height=726px');
      } else {
        alert(t('유효하지 않은 결제 타입입니다.'));
      }
    } else {
      alert(t('결제시스템 토큰 정보가 없음. 다시 로그인 하십시요.'));
      doLogOut();
    }
  }

  const handleOnPay = (type: string) => {
    if (!isLoggedIn) {
      navigate('/login');
    } else {
      console.log('[handleOnPay] userInfo:' + JSON.stringify(userInfo));
      refreshPaySignInWithFee(userInfo?.userLogin, type, refreshPaySignInCB);
    }
  };

  return (
    <div
      className={`${
        isSubscription ? 'h-72' : 'h-[390px] lg:h-96'
      } flex justify-center items-center border-2 border-primary rounded-[10px] bg-[#0a101b] md:hover:bg-primary/70 shadow-lg md:hover:shadow-2xl md:hover:-translate-y-2.5 group duration-150 ${
        isActive &&
        'max-md:bg-primary/70 max-md:-translate-y-2.5 max-md:shadow-2xl'
      }`}>
      <div className="flex flex-col text-center">
        <h2 className="text-2xl font-bold">{t(membership)}</h2>
        {description && (
          <p className="whitespace-pre-wrap text-lightGray text-lg mt-4 lg:mt-6">
            {t(description)}
          </p>
        )}
        {pay ? (
          <h3 className="mt-4">
            {pay.toLocaleString()}{' '}
            <span className="text-sm text-lightGray/50">
              {isSubscription ? t('(x 12개월)') : t('/월')}
            </span>
          </h3>
        ) : (
          <h3 className="mt-4">{t('매월 무료 제공')}</h3>
        )}
        <span className="text-sm text-lightGray/50">
          {pay ? t('(VAT 별도)') : t('(워터마크 / 홍보영상 포함)')}
        </span>
        <span className="mt-5 mb-1 text-2xl font-bold">
          {(isSubscription ? credit * 12 : credit).toLocaleString()}{' '}
          {t('크레딧')}
        </span>
        {limit ? (
          <span className="text-sm font-medium mt-5">
            {t('영상 제작')}: {limit}
            {t('분')}
          </span>
        ) : (
          <span className="text-xs lg:text-sm">
            {t('매월 지급')}: {credit.toLocaleString()} {t('크레딧')}
          </span>
        )}
        <button
          disabled={membership === 'Entry' && isLoggedIn}
          onClick={() => handleOnPay(membership)}
          className="bg-primary border-2 border-primary disabled:bg-primary/50 disabled:text-white/50 disabled:cursor-not-allowed rounded-md text-sm py-2.5 lg:py-3 font-bold mt-2 md:hidden md:group-hover:block">
          {membership === 'Entry' ? t('가입하기') : t('신청하기')}
        </button>
      </div>
    </div>
  );
}
