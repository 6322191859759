import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { lang } from '../../recoil/atoms/user';
import CheckIcon from '../svg/check-icon';
import GlobeIcon from '../svg/globe-icon';
import SelectBoxArrowIcon from '../svg/select-box-arrow-icon';

const languageList = [
  { label: 'KOR', value: 'ko' },
  { label: 'EN', value: 'en' },
];

type Props = {
  isHeader?: boolean;
};
export default function LanguageListBox({ isHeader }: Props) {
  const [selected, setSelected] = useState(languageList[0]);
  const [language, setLanguage] = useRecoilState(lang);
  const currentLang = languageList.find(item => item.value === language)?.label;
  const { i18n } = useTranslation();
  const { origin, pathname } = window.location;

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    // changeLang(lang);
  };

  const changeLang = (lang: string) => {
    const isInEnglish = pathname.includes('en');
    const isInKorean = !pathname.includes('en');
    switch (lang) {
      case 'ko':
        if (isInKorean) return;
        window.location.href = origin + pathname.replace('/en', '');
        break;
      case 'en':
        if (isInEnglish) return;
        window.location.href = origin + '/en' + pathname;
        break;
      default:
    }
  };

  useEffect(() => {
    setDefaultLanguageValue();

    function setDefaultLanguageValue() {
      setTimeout(() => {
        const { pathname } = window.location;
        const isInEnglish = pathname.includes('en');
        if (isInEnglish) {
          setSelected(languageList[1]);
        } else {
          setSelected(languageList[0]);
        }
      }, 10);
    }
  }, []);

  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className="relative w-[100px]">
        <Listbox.Button
          className={`relative flex items-center space-x-3 p-2 w-full leading-6 text-white border border-white duration-100 ease-out ${
            isHeader
              ? 'bg-white/10 rounded-full hover:bg-white/20'
              : 'bg-[#191919] rounded-md text-sm'
          }`}>
          <i className="min-w-fit">
            <GlobeIcon />
          </i>
          <span className="max-2xl:text-sm">{currentLang}</span>
          <i className="min-w-fit">
            <SelectBoxArrowIcon />
          </i>
        </Listbox.Button>
        <Transition
          as={Fragment}
          enter="ease-in-out duration-150"
          enterFrom="opacity-0 -translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in-out duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-2">
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-[#191919] text-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {languageList.map(({ label, value }) => (
              <Listbox.Option
                key={value}
                onClick={() => handleChangeLanguage(value)}
                className={({ active }) =>
                  `relative cursor-pointer py-2 pl-10 pr-4 ${
                    active && 'bg-primary'
                  }`
                }
                value={value}>
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}>
                      {label}
                    </span>
                    {selected && (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <CheckIcon className="stroke-primary" />
                      </span>
                    )}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
