import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { MapType } from '../store/block/types';

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function findValueByKey(
  array: { key: string; value: string }[],
  key: string
) {
  const foundItem = array.find((item: any) => item.key === key);
  return foundItem?.value as any;
}

export function formatTelNumber(telNumber: string) {
  if (!telNumber) return;

  return telNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
}

export function formatDuration(duration: number) {
  const minutes = Math.floor(duration / 100 / 60);
  const seconds = Math.floor((duration % 6000) / 100);
  const milliseconds = duration % 100;

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  const formattedMilliseconds = String(milliseconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
}

export function getTimeInterval(insertTime: number, duration: number) {
  const startMinutes = Math.floor(insertTime / 100 / 60);
  const startSeconds = Math.floor((insertTime % 6000) / 100);
  const endMinutes = Math.floor((duration + insertTime) / 100 / 60);
  const endSeconds = Math.floor(((duration + insertTime) % 6000) / 100);

  const formattedStartMinutes = String(startMinutes).padStart(2, '0');
  const formattedStartSeconds = String(startSeconds).padStart(2, '0');
  const formattedEndMinutes = String(endMinutes).padStart(2, '0');
  const formattedEndSeconds = String(endSeconds).padStart(2, '0');

  return `${formattedStartMinutes}:${formattedStartSeconds} ~ ${formattedEndMinutes}:${formattedEndSeconds}`;
}

export function isIn(
  { insertTime, duration }: { insertTime: number; duration: number },
  currentTime: number
) {
  return insertTime <= currentTime && insertTime + duration > currentTime;
}

export function formatTime(timeValue: number) {
  const minute = (timeValue / (100 * 60)) % 60;
  const second = (timeValue / 100) % 60;
  const time = String(Math.ceil(timeValue));
  const minuteCut = `0${Math.floor(minute)}`.slice(-2);
  const secondCut = `0${Math.floor(second)}`.slice(-2);

  return `${minuteCut}:${secondCut}.${time.padStart(2, '0').slice(-2)}`;
}

export function createUuid() {
  return Number(
    uuid()
      .replace(/[^0-9]/g, '')
      .substring(0, 12)
  );
}

export function displayScale(cs: number) {
  const time = cs * 100;
  const minute = (time / (100 * 60)) % 60;
  const second = (time / 100) % 60;
  const minuteCut = `${Math.floor(minute)}`.slice(-2);
  const secondCut = `0${Math.floor(second)}`.slice(-2);
  return `${minuteCut}:${secondCut}`;
}

export function formatDate(date: string) {
  return +date.replace(/-/g, '');
}

export function findExtension(fileName: string) {
  const imageExtensions = ['.gif', '.png', '.jpg', '.jpeg', '.bmp'];
  const videoExtensions = ['.mp4', '.avi', '.mov', '.wmv'];
  const mp3Extensions = ['.mp3', '.wav'];

  const fileExtension = fileName.toLowerCase().slice(fileName.lastIndexOf('.'));

  if (fileExtension === '.pptx' || fileExtension === 'ppt') return 'ppt';
  if (fileExtension === '.pdf') return 'pdf';
  if (imageExtensions.includes(fileExtension)) return 'image';
  if (videoExtensions.includes(fileExtension)) return 'video';
  if (mp3Extensions.includes(fileExtension)) return 'audio';

  return 'unknown';
}

export function formatFileSize(sizeInBytes: number) {
  const KB = 1024;
  const MB = 1024 * KB;

  if (sizeInBytes < MB) {
    const sizeInKB = (sizeInBytes / KB).toFixed(2);
    return `${sizeInKB}KB`;
  }
  const sizeInMB = (sizeInBytes / MB).toFixed(2);
  return `${sizeInMB}MB`;
}

export function getTypeForBlock(name: string) {
  switch (name) {
    case '영상':
    case '메타휴먼':
    case '배경음악':
    case '효과음':
      return 'segment';
    case '배경':
      return 'background';
    case '이미지':
      return 'image';
    case '텍스트':
      return 'title';
    default:
      return '';
  }
}

export function getCtrlIcon() {
  const isMacOS = navigator.userAgent.includes('Macintosh');
  return isMacOS ? '⌘(cmd)' : 'ctrl';
}

export function getIdName(type: string) {
  const idMap: MapType = {
    background: 'backgroundId',
    image: 'imageId',
    segment: 'segmentId',
    title: 'titleId',
  };

  return idMap[type];
}

export function sortByInsertTime(array: any[]) {
  const sortedArray = [...array];
  return sortedArray.sort((a, b) => a.insertTime - b.insertTime);
}

export function findClosestEarlierItem(array: any[], specificItem: any) {
  return array.reduce(
    (closestItem, item) => {
      if (item.insertTime < specificItem.insertTime) {
        const diff = specificItem.insertTime - item.insertTime;
        if (diff < closestItem.timeDifference) {
          return { item, timeDifference: diff };
        }
      }
      return closestItem;
    },
    { item: null, timeDifference: Infinity }
  ).item;
}

export function getRedirectParam(membership: string) {
  switch (membership) {
    case 'Basic':
    case 'Professional':
    case 'Business':
      return '/home/mypage/creditStudioNew';
    case 'Basic Plus':
    case 'Professional Plus':
    case 'Business Plus':
      return '/home/mypage/creditStudioPlanNew';
    default:
      return '';
  }
}

/**
 * 아이템 코드에 넣을 난수 값을 구한다.
 * 년월일시분초_난수6자리 => 20230101133040_000000
 */
export const getItemCode = () => {
  const code1 = moment().format('YYYYMMDDHHmmss');
  const code2 = String(rand(1, 999999)).padStart(6, '0');
  return code1 + '_' + code2;
};

const rand = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getValueByKey = (
  array: { key: string; value: string }[],
  key: string
) => array.find(item => item.key === key)?.value;

export function getOrder(idx: number, paged: number) {
  return idx + 1 + (paged - 1) * 10;
}

export function getLabelByValue(value: string, array: any[]) {
  return array.find(item => item.value === value)?.label;
}

export function stringify(object: any) {
  return JSON.stringify(object);
}

export const changeHost = url => {
  if (!url) return '';
  if (process.env.REACT_APP_SERVICE_MODE === 'production') return url;

  const serverURL = process.env.REACT_APP_HTTP_URL;
  const domainURL = process.env.REACT_APP_DOMAIN_URL;

  return url.replace(serverURL, domainURL);
};

/**
 * 문자열 변수의 empty check
 * @param value 문자열
 * @returns 
 */
export const isEmptyStr = (value:string) => {
  if (value === null || value.length === 0 || value.trim().length === 0) return true;
  return false;
}
