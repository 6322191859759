import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { rails, pages as storedPages } from '../../store/pages';
import { IPages } from '../../store/pages/types';
import ToolTip from '../common/tool-tip';
import RedoIcon from '../svg/redo-icon';
import UncoIcon from '../svg/undo-icon';

const BUTTON_STYLE = 'w-5 h-5 group disabled:cursor-not-allowed';

export default function UndoRedoButtons() {
  const { t } = useTranslation();
  const [pages, setPages] = useRecoilState<IPages[]>(storedPages);
  const segments = pages[0]?.segments[0]?.segment;
  const titles = pages[0]?.segments[0]?.title;
  const backgrounds = pages[0]?.segments[0]?.background;
  const images = pages[0]?.segments[0]?.image;
  const [pagesHistory, setPagesHistory] = useState([]);
  const [undidHistory, setUndidHistory] = useState([]);

  const lastState = pagesHistory[pagesHistory.length - 2];
  const isUndoDisabled =
    (!segments?.length ||
      !titles?.length ||
      !backgrounds?.length ||
      !images?.length) &&
    pagesHistory.length <= 1;
  const isRedoDisabled = !undidHistory.length;

  const [railArray, setRailArray] = useRecoilState(rails);

  useEffect(() => {
    if (pagesHistory.length <= 1 || 
      JSON.stringify(pagesHistory[pagesHistory.length - 1]) !== JSON.stringify(pages[0])) {
      const history = [...pagesHistory, ...pages].slice(-10);
      setPagesHistory(history);
    }
  }, [pages]);

  const handleUndo = () => {
    checkRail([lastState]);
    setPages([lastState]);
    setPagesHistory(prev => prev.slice(0, -2));
    setUndidHistory(prev => [...prev, pagesHistory[pagesHistory.length - 1]]);
  };

  const handleRedo = () => {
    const redoingState = undidHistory[undidHistory.length - 1];
    setUndidHistory(prev => prev.slice(0, -1));    
    checkRail([redoingState]);
    setPages([redoingState]);
  };

  const getMaxRailIndex = (array) => array.reduce((max, item) => {
    return item.railIndex !== undefined ? Math.max(max, item.railIndex) : max;
  }, 0);  
  
  const checkAndUpdateRailArray = (maxIndex, threshold, type) => { // railIndex의 크기만큼 rail을 추가한다.
    if (maxIndex >= threshold) {
      const newItems = Array(maxIndex - threshold + 1).fill({ type });
      setRailArray([...railArray, ...newItems]);
    } 
  };

  function getRailCnt(array, type) {
    return railArray?.filter(
      item => item.type === type
    ).length;
  }

  const checkRail = (pages: IPages[]) => { 
    const railCntBG = getRailCnt(railArray, 'background');
    const railCntClip = getRailCnt(railArray, 'clip');

    const targetArrayBG = pages[0]?.segments[0] || {};
    const backgroundMax = getMaxRailIndex(targetArrayBG.background || []);
    checkAndUpdateRailArray(backgroundMax, railCntBG, 'background');
    
    const imageMax = getMaxRailIndex(targetArrayBG.image || []);
    const titleMax = getMaxRailIndex(targetArrayBG.title || []);
    checkAndUpdateRailArray(Math.max(imageMax, titleMax), railCntClip, 'clip');
  }

  return (
    <>
      <ToolTip title={t('실행취소')}>
        <button
          className={BUTTON_STYLE}
          disabled={isUndoDisabled}
          onClick={handleUndo}>
          <UncoIcon />
        </button>
      </ToolTip>
      <ToolTip title={t('되돌리기')}>
        <button
          className={BUTTON_STYLE}
          disabled={isRedoDisabled}
          onClick={handleRedo}>
          <RedoIcon />
        </button>
      </ToolTip>
    </>
  );
}
