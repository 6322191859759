/* eslint-disable */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import '../../css/reset.css';
import '../../css/style.css';
import FileUpload from '../../images/common/file_upload.png';
import '../../locale/i18n';
import {
  addResource,
  delResource,
  getResourceList,
} from '../../networks/source.service';
import { block } from '../../store/block';
import {
  additionalTarget,
  centiSecond,
  markerPos,
  pages,
  rails,
  setSegmentsInfo,
  segmentsItem as storedSegmentItem,
} from '../../store/pages';
import { checkFileSize, isEnableMediaResourceType, isEnableResourceType } from '../../util/common';
import VideoModal from '../VideoModal';
import StoreButton from '../common/StoreButton';
import Loader from '../lottie/Loader';
import ColorPicker from './ColorPicker';
import ImageTab from './background/ImageTab';
import MusicTab from './background/MusicTab';
import VideoTab from './background/VideoTab';

export default function BackgroundModal({ active, setActive, disabled }: any) {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [page, setPage] = useRecoilState(pages);
  const [railArray, setRailArray] = useRecoilState(rails);
  const [selectBlock, setSelectBlock] = useRecoilState(block);
  const setSegmentsItems = useSetRecoilState(storedSegmentItem);
  const addTarget = useRecoilValue(additionalTarget);
  const markerX = useRecoilValue(markerPos);
  const cs = useRecoilValue(centiSecond);
  const [dragOver, setDragOver] = useState(false);
  /**
   * 1 => 이미지
   * 2 => 컬러
   * 3 => 영상
   * 4 => 음악
   */
  const [imageActive, setImageActive] = useState(1);
  const [backgroundInfo, setBackgroundInfo] = useState({
    type: '',
    sourceURL: '',
  });
  const [modal, setModal] = useState({
    open: false,
    url: '',
  });
  const [uploadLoading, setUploadLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const openModal = (url: string) => {
    setModal({ open: true, url });
  };

  const selectedBlockDuration = useMemo(() => {
    return page[0]?.segments[0]?.segment[selectBlock.groupIndex]?.duration;
  }, [markerX, page[0]?.segments[0]?.segment[0]?.insertTime]);

  const selectedBlockInsertTime = useMemo(() => {
    return page[0]?.segments[0]?.segment[selectBlock.groupIndex]?.insertTime;
  }, [markerX, page[0]?.segments[0]?.segment[0]?.insertTime]);

  const selectedBlockIndexKey = useMemo(() => {
    return page[0]?.segments[0]?.segment[selectBlock.groupIndex]?.indexKey;
  }, [markerX, page[0]?.segments[0]?.segment[0]?.insertTime]);

  const addBackground = (item: any, type: string) => {
    const tmpPage = JSON.parse(JSON.stringify(page));
    let maxInsertTime = 0;
    let bgInsertT = 0;
    let tmpArray = page[0]?.segments[0]?.background?.filter(
      item => item.railIndex === 0
    );

    tmpArray.map(item => {
      if (maxInsertTime <= item.insertTime + item.duration) {
        maxInsertTime = item.insertTime + item.duration;
      }
    });
    bgInsertT = maxInsertTime;

    let duration = 200;
    let insertT = 0;
    let railIdx = 0;

    if (addTarget.name === 'marker') {
      const targetMeta = tmpPage[0]?.segments[0]?.segment?.filter(
        item =>
          item?.insertTime <= addTarget?.insertT &&
          item?.insertTime + item?.duration >= addTarget?.insertT &&
          item.mediaType === 'metahuman'
      )[0];

      const targetArray = [
        ...tmpPage[0]?.segments[0]?.background,
        ...tmpPage[0]?.segments[0]?.segment?.filter(
          item => item.mediaType === 'bgaudio'
        ),
      ]?.filter(item => {
        return (
          item?.insertTime + item?.duration - targetMeta?.insertTime > 0 &&
          item?.insertTime - targetMeta?.insertTime < targetMeta?.duration
        );
      });

      let railIndex = 0;

      targetArray?.map(item => {
        if (item.railIndex > railIndex) {
          railIndex = item.railIndex;
        }
      });
      duration = targetMeta ? targetMeta?.duration : 200;
      insertT = targetMeta ? targetMeta?.insertTime : bgInsertT;
      railIdx = targetArray.length === 0 ? 0 : railIndex + 1;
    } else {
      const targetArray = tmpPage[0]?.segments[0]?.segment?.filter(item => {
        return (
          item.insertTime + item.duration - addTarget?.insertT?.insertTime >
            0 &&
          item.insertTime - addTarget?.insertT?.insertTime <
            addTarget?.insertT?.duration &&
          item.mediaType === 'metahuman'
        );
      });

      const maxArray = targetArray.length
        ? targetArray?.reduce((pre, cur) => {
            return pre.insertTime > cur.insertTime ? pre : cur;
          })
        : [];

      const targetMHArray = [
        ...tmpPage[0]?.segments[0]?.background,
        ...tmpPage[0]?.segments[0]?.segment?.filter(
          item => item.mediaType === 'bgaudio'
        ),
      ]?.filter(item => {
        return (
          item.insertTime + item.duration - maxArray.insertTime > 0 &&
          item.insertTime - maxArray.insertTime < maxArray.duration
        );
      });

      let railIndex = 0;
      targetMHArray?.map(item => {
        if (item.railIndex > railIndex) {
          railIndex = item.railIndex;
        }
      });
      duration = maxArray.length ? maxArray?.duration : 200;
      railIdx = targetMHArray.length === 0 ? 0 : railIndex + 1;
    }

    if (type === 'bgaudio') {
      const minute = item.lengthFormatted?.split(':')[0];
      const second = item.lengthFormatted?.split(':')[1];

      if (
        Object.keys(selectBlock).length &&
        type ===
          tmpPage[0].segments[0].segment[selectBlock.groupIndex].mediaType
      ) {
        if (selectBlock.segmentsGroup === 'segment') {
          const insertTime =
            tmpPage[0].segments[0].segment[selectBlock.groupIndex]?.inserTime;
          tmpPage[0].segments[0].segment[selectBlock.groupIndex] = {
            ...tmpPage[0].segments[0].segment[selectBlock.groupIndex],
            mediaType: 'bgaudio',
            color: item.colors || '',
            filePath: item.filePath || '',
            source: item.source || '',
            previewImage: item.previewImage || '',
            fileName: item.fileName,
            duration:
              second !== undefined
                ? Math.floor((Number(minute) * 60 + Number(second)) * 100)
                : 200,
            endTime:
              second !== undefined
                ? insertTime +
                  Math.floor((Number(minute) * 60 + Number(second)) * 100)
                : insertTime + 200,
          };
          setPage(tmpPage);
        }
      } else {
        const id = Date.now();
        setSegmentsInfo(page, setPage, {
          pagesIndex: 0,
          segment: {
            segmentArray: [
              ...tmpPage[0].segments[0].segment,
              {
                segmentId: id,
                contentId: id,
                mediaType: 'bgaudio',
                color: item.colors || '',
                filePath: item.filePath || '',
                source: item.source || '',
                previewImage: item.previewImage || '',
                fileName: item.fileName,
                rank: 0,
                insertTime: selectedBlockInsertTime
                  ? selectedBlockInsertTime
                  : cs,
                duration:
                  second !== undefined
                    ? Math.floor((Number(minute) * 60 + Number(second)) * 100)
                    : 200,
                startTime: 0,
                endTime:
                  second !== undefined
                    ? (selectedBlockInsertTime ? selectedBlockInsertTime : cs) +
                      Math.floor((Number(minute) * 60 + Number(second)) * 100)
                    : (selectedBlockInsertTime ? selectedBlockInsertTime : cs) +
                      200,
                indexKey: selectedBlockIndexKey,
                fadeIn: 0,
                fadeOut: 0,
                mute: 'N',
                volume: 1.0,
                transitionType: '',
                transitionTime: '',
                zIndex: 0,
                railIndex: railIdx,
              },
            ],
          },
        });
        if (
          railArray?.filter(item => item.type === 'background')?.length ===
          railIdx
        ) {
          setRailArray([...railArray, { type: 'background' }]);
        }
        setSelectBlock({
          segmentsGroup: 'segment',
          groupIndex: tmpPage[0]?.segments[0]?.segment?.length,
        });
      }
    } else {
      const minute = item.lengthFormatted?.split(':')[0];
      const second = item.lengthFormatted?.split(':')[1];

      if (
        Object.keys(selectBlock).length &&
        type === tmpPage[0].segments[0].background[selectBlock.groupIndex]?.type
      ) {
        if (selectBlock.segmentsGroup === 'background') {
          tmpPage[0].segments[0].background[selectBlock.groupIndex] = {
            ...tmpPage[0].segments[0].background[selectBlock.groupIndex],
            color: item.colors || '',
            previewImage: item.previewImage || '',
            sourceURL: item.filePath || '',
            sourcePath: item.filePath || '',
          };
          setPage(tmpPage);
        }
      } else {
        const id = Date.now();

        if (type === 'image' || type === 'color') {
          setSegmentsInfo(page, setPage, {
            pagesIndex: 0,
            background: {
              backgroundArray: [
                ...tmpPage[0].segments[0].background,
                {
                  backgroundId: id,
                  type: type,
                  contentId: id,
                  color: item.colors || '',
                  previewImage: item.previewImage || '',
                  sourceURL: item.filePath || '',
                  sourcePath: item.filePath || '',
                  rank: 0,
                  insertTime: selectedBlockInsertTime
                    ? selectedBlockInsertTime
                    : cs,
                  duration: selectedBlockDuration ? selectedBlockDuration : 300,
                  startTime: 0,
                  endTime:
                    (selectedBlockInsertTime ? selectedBlockInsertTime : cs) +
                    (selectedBlockDuration ? selectedBlockDuration : 300),
                  indexKey: selectedBlockIndexKey,
                  fadeIn: 0,
                  fadeOut: 0,
                  mute: 'N',
                  volume: 1.0,
                  transitionType: '',
                  transitionTime: '',
                  zIndex: 0,
                  railIndex: railIdx,
                },
              ],
            },
          });

          if (
            railArray?.filter(item => item.type === 'background')?.length ===
            railIdx
          ) {
            setRailArray([...railArray, { type: 'background' }]);
          }

          setSelectBlock({
            segmentsGroup: 'background',
            groupIndex: tmpPage[0]?.segments[0]?.background?.length || 0,
          });
        } else {
          setSegmentsInfo(page, setPage, {
            pagesIndex: 0,
            background: {
              backgroundArray: [
                ...tmpPage[0].segments[0].background,
                {
                  backgroundId: id,
                  type: type,
                  contentId: id,
                  color: item.colors || '',
                  previewImage: item.previewImage || '',
                  sourceURL: item.filePath || '',
                  sourcePath: item.filePath || '',
                  rank: 0,
                  insertTime: selectedBlockInsertTime
                    ? selectedBlockInsertTime
                    : cs,
                  duration:
                    second !== undefined
                      ? Math.floor((Number(minute) * 60 + Number(second)) * 100)
                      : 200,
                  startTime: 0,
                  endTime:
                    second !== undefined
                      ? (selectedBlockInsertTime
                          ? selectedBlockInsertTime
                          : cs) +
                        Math.floor((Number(minute) * 60 + Number(second)) * 100)
                      : (selectedBlockInsertTime
                          ? selectedBlockInsertTime
                          : cs) + 200,
                  indexKey: selectedBlockIndexKey,
                  fadeIn: 0,
                  fadeOut: 0,
                  mute: 'N',
                  volume: 1.0,
                  transitionType: '',
                  transitionTime: '',
                  zIndex: 0,
                  railIndex: railIdx,
                },
              ],
            },
          });
          if (
            railArray?.filter(item => item.type === 'background')?.length ===
            railIdx
          ) {
            setRailArray([...railArray, { type: 'background' }]);
          }
          setSegmentsItems({ id: id, type: type });
          setSelectBlock({
            segmentsGroup: 'background',
            groupIndex: tmpPage[0]?.segments[0]?.background?.length || 0,
          });
        }
      }
    }
  };

  // 새 이미지 추가
  const addNewElement = () => setSelectBlock({});
  // 블록 색상

  const [blockColor, setBlockColor] = useState();

  // 배경 수정
  useEffect(() => {
    console.log('[BackgroundModal][useEffect] -------- 1');

    if (Object.keys(selectBlock).length !== 0 && page) {
      if (
        selectBlock?.segmentsGroup === 'background' &&
        page[0]?.segments[0][selectBlock.segmentsGroup][selectBlock.groupIndex]
          ?.type === 'image'
      ) {
        setActive(2);
        setImageActive(1);
        setBackgroundInfo({
          type: 'image',
          sourceURL:
            page[0]?.segments[0][selectBlock.segmentsGroup][
              selectBlock.groupIndex
            ]?.sourceURL,
        });
      } else if (
        selectBlock?.segmentsGroup === 'background' &&
        page[0]?.segments[0][selectBlock.segmentsGroup][selectBlock.groupIndex]
          ?.type === 'video'
      ) {
        setActive(2);
        setImageActive(3);
        setBackgroundInfo({
          type: 'video',
          sourceURL:
            page[0]?.segments[0][selectBlock.segmentsGroup][
              selectBlock.groupIndex
            ]?.sourceURL,
        });
      } else if (
        selectBlock?.segmentsGroup === 'segment' &&
        page[0]?.segments[0][selectBlock.segmentsGroup][selectBlock.groupIndex]
          ?.mediaType === 'bgaudio'
      ) {
        setActive(2);
        setImageActive(4);
        setBackgroundInfo({
          type: 'bgaudio',
          sourceURL:
            page[0]?.segments[0][selectBlock.segmentsGroup][
              selectBlock.groupIndex
            ]?.filePath,
        });
      } else if (
        selectBlock?.segmentsGroup === 'background' &&
        page[0]?.segments[0][selectBlock.segmentsGroup][selectBlock.groupIndex]
          ?.type === 'color'
      ) {
        setActive(2);
        setImageActive(2);
        setBackgroundInfo({
          type: 'color',
          sourceURL: '',
        });
        setBlockColor(
          page[0]?.segments[0][selectBlock.segmentsGroup][
            selectBlock.groupIndex
          ]?.color
        );
      }
    } else {
      setBackgroundInfo({
        type: '',
        sourceURL: '',
      });
    }
  }, [selectBlock]);

  const [audioList, setAudioList] = useState([]);

  // 리소스 업로드
  const uploadResource = (e: any, type: string) => {
    const fd = new FormData();

    Array.from(e.target.files).map((file: any) => {
      // console.log('[BackgroundModal] uploadResource -> file.type:', file.type);
      if (!isEnableMediaResourceType(file.type)) {
        alert(t('파일 형식을 확인해주세요.'));
        return;
      }
      if (checkFileSize(file)) {
        fd.append('resource[]', file);
      } else {
        alert(t('최대 업로드 사이즈를 초과 하였습니다.'));
        return;
      }
    });

    fd.append('group', 'background');
    fd.append('type', type);

    setUploadLoading(true);
    addResource(fd)
      .then(res => {
        console.log('리소스 업로드 ====>', res.data.body);
        if (res.data.code === '501') {
          if (type === 'image') {
            alert(t('이미지 파일만 업로드 가능합니다.'));
          } else if (type === 'video') {
            alert(t('동영상 파일만 업로드 가능합니다.'));
          } else if (type === 'audio') {
            alert(t('오디오 파일만 업로드 가능합니다.'));
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setUploadLoading(false));
  };

  // 리소스 삭제
  const deleteResource = (id: number) => {
    setDeleteLoading(true);
    delResource({ resourceId: id })
      .then(res => {
        console.log('리소스 삭제 ====>', res.data.body);
      })
      .catch(err => console.log(err))
      .finally(() => setDeleteLoading(false));
  };

  const dragFunction = useCallback((event, action, type) => {
    event.preventDefault();
    event.stopPropagation();
    if (action === 'drop') {
      const fileType = event?.dataTransfer?.files[0]?.type;
      // console.log('[BackgroundModal] dragFunction -> file.type:', fileType);
      if (!isEnableMediaResourceType(fileType)) {
        alert(t('파일 형식을 확인해주세요'));
        setDragOver(false);
        return;
      }

      if (!checkFileSize(event?.dataTransfer?.files[0])) {
        alert(t('최대 업로드 사이즈를 초과 하였습니다.'));
        setDragOver(false);
        return;
      }
    }
    if (action === 'over') setDragOver(true);
    if (action === 'leave') setDragOver(false);
    if (action === 'drop') {
      setDragOver(false);
      const fd = new FormData();
      Array.from(event.dataTransfer.files).map((file: any) => {
        fd.append('resource[]', file);
      });

      fd.append('group', 'background');
      fd.append('type', type);

      addResource(fd)
        .then(() => {
          getResourceList({
            group: 'background',
            type,
            projectId: Number(projectId),
            isMine: 1,
            lang: process.env.REACT_APP_LANGUAGE,
          })
            .then(res => {
              const data = res.data.body.resources;
              if (type === 'audio') {
                let tmpArray = [];
                data.map((item: any) => {
                  tmpArray.push(new Audio(item.source));
                });
                setAudioList(tmpArray);
              }
            })
            .catch(console.log);
        })
        .catch(console.log);
    }
  }, []);

  return (
    <>
      <VideoModal
        isOpen={modal.open}
        closeModal={() => setModal(prev => ({ ...prev, open: false }))}
        videoURL={modal.url}
      />
      <li>
        <button
          disabled={disabled}
          className={active == 2 ? 'active' : 'disabled:cursor-not-allowed'}
          onClick={() => {
            setActive(2);
            setSelectBlock({});
          }}>
          <i className="style__background__icon" />
          <span>{t('배경')}</span>
        </button>

        <div className="background h-full max-h-[600px]">
          <div className="title">
            <StoreButton t={t} category={'배경'} />
            <button
              className="close__btn close__gray__icon"
              onClick={() => {
                setActive(0);
              }}
            />
          </div>
          <div className="tab__list">
            <button
              className={imageActive === 2 && 'active'}
              onClick={() => {
                setImageActive(2);
                setBackgroundInfo({ ...backgroundInfo, type: 'color' });
                setSelectBlock({});
              }}>
              {t('컬러')}
            </button>
            <button
              className={imageActive === 1 && 'active'}
              onClick={() => {
                setImageActive(1);
                setBackgroundInfo({ ...backgroundInfo, type: 'image' });
                setSelectBlock({});
              }}>
              {t('이미지')}
            </button>
            <button
              className={imageActive === 3 && 'active'}
              onClick={() => {
                setImageActive(3);
                setBackgroundInfo({ ...backgroundInfo, type: 'video' });
                setSelectBlock({});
              }}>
              {t('영상')}
            </button>
            <button
              className={imageActive === 4 && 'active'}
              onClick={() => {
                setImageActive(4);
                setBackgroundInfo({ ...backgroundInfo, type: 'bgaudio' });
                setSelectBlock({});
              }}>
              {t('음악')}
            </button>
          </div>
          <div className="content">
            <div
              className={
                imageActive === 2 ? 'color__content show' : 'color__content'
              }>
              <ColorPicker
                addBackground={addBackground}
                blockColor={blockColor}
              />
            </div>

            <ImageTab
              isModalActive={active === 2}
              imageActive={imageActive}
              projectId={projectId}
              t={t}
              setBackgroundInfo={setBackgroundInfo}
              backgroundInfo={backgroundInfo}
              addBackground={addBackground}
              active={active}
              dragFunction={dragFunction}
              dragOver={dragOver}
              uploadLoading={uploadLoading}
              deleteLoading={deleteLoading}
              Loader={Loader}
              uploadResource={uploadResource}
              FileUpload={FileUpload}
              deleteResource={deleteResource}
            />
            <VideoTab
              isModalActive={active === 2}
              imageActive={imageActive}
              projectId={projectId}
              t={t}
              setBackgroundInfo={setBackgroundInfo}
              backgroundInfo={backgroundInfo}
              addBackground={addBackground}
              active={active}
              dragFunction={dragFunction}
              dragOver={dragOver}
              uploadLoading={uploadLoading}
              deleteLoading={deleteLoading}
              Loader={Loader}
              uploadResource={uploadResource}
              FileUpload={FileUpload}
              deleteResource={deleteResource}
              openModal={openModal}
              addNewElement={addNewElement}
            />
            <MusicTab
              isModalActive={active === 2}
              active={active}
              imageActive={imageActive}
              projectId={projectId}
              t={t}
              setBackgroundInfo={setBackgroundInfo}
              backgroundInfo={backgroundInfo}
              addBackground={addBackground}
              dragFunction={dragFunction}
              dragOver={dragOver}
              uploadLoading={uploadLoading}
              deleteLoading={deleteLoading}
              Loader={Loader}
              uploadResource={uploadResource}
              FileUpload={FileUpload}
              deleteResource={deleteResource}
            />
            {Object.keys(selectBlock).length !== 0 && imageActive === 1 ? (
              <button
                className="edit-new fixed top-[590px] right-32"
                onClick={() => {
                  addNewElement();
                  setBackgroundInfo({ type: '', sourceURL: '' });
                }}>
                {t('새 이미지 추가')}
              </button>
            ) : Object.keys(selectBlock).length !== 0 && imageActive === 3 ? (
              <button
                className="edit-new fixed top-[590px] right-32"
                onClick={() => {
                  addNewElement();
                  setBackgroundInfo({ type: '', sourceURL: '' });
                }}>
                {t('새 영상 추가')}
              </button>
            ) : Object.keys(selectBlock).length !== 0 && imageActive === 2 ? (
              <button
                className="color_btn fixed top-[590px] right-32"
                onClick={() => {
                  addNewElement();
                  setBackgroundInfo({ type: '', sourceURL: '' });
                }}>
                {t('새 컬러 추가')}
              </button>
            ) : null}
          </div>
        </div>
      </li>
    </>
  );
}
