import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import '../../css/reset.css';
import '../../css/style.css';
import Coin from '../../images/icons/studio-side/ico-coin-purple.svg';
import ExclamationMark from '../../images/icons/studio-side/ico-exclamationMark-purple.svg';
import PreviewPopupIcon from '../../images/icons/studio-side/icon-circle.svg';
import PopupSelectBtn from '../../images/icons/studio-side/icon-popup-arrow.svg';
import { msToTime } from '../../lib/common';
import '../../locale/i18n';
import { getLeftCredit } from '../../networks/paysystem.service';
import { doGetProjectInfo } from '../../networks/project.service';
import { getStoragePaysystemCompanySeq } from '../../networks/store/paysystem';
// import { getMe } from '../../services/auth';
import useEntireDuration from '../../hooks/use-entire-duration';
import { getMe } from '../../networks/member.service';
import {
  changeProjectName,
  createPreview,
  createVideo,
  saveProject,
} from '../../services/project';
import { BEFORE_UNLOAD } from '../../store/common/beforeunload';
import { TOGGLE_LOADING } from '../../store/common/loading';
import { autoScript as autoScriptArray, pages, rails } from '../../store/pages';
import { IPages, ISegments } from '../../store/pages/types';
import ButtonSection from '../header/ButtonSection';
import Title from '../header/Title';
import ExportModal from '../popup/export/ExportModal';
import ExportVoiceModal from '../popup/export/ExportVoiceModal';
import ExtensionPopup from '../popup/export/ExtensionPopup';
import QualityPopup from '../popup/export/QualityPopup';
import TotalCredit from '../popup/export/TotalCredit';
import PreviewModal from '../popup/preview/PreviewModal';
import PreviewSelectModal from '../popup/preview/PreviewSelectModal';
import SaveModal from '../popup/save/SaveModal';

const SubHeader = ({ setControls }) => {
  const entireDuration = useEntireDuration();
  const { t } = useTranslation();
  const loading = useSetRecoilState(TOGGLE_LOADING);
  const { projectId } = useParams();
  const [projectInfo, setProjectInfo] = useRecoilState<IPages[]>(pages);
  const segment = projectInfo[0]?.segments[0]?.segment;
  const [isExported, setIsExported] = useState(false);
  const [playTime, setPlayTime] = useState<number>();
  const rail = useRecoilValue(rails);
  const [previewInfo, setPreviewInfo] = useState({
    result: '',
    previewNum: '',
  });
  const navigate = useNavigate();
  const setBeforeunload = useSetRecoilState(BEFORE_UNLOAD);

  const getPlayTime = () => {
    const metahumans = projectInfo[0]?.segments[0]?.segment?.filter(
      seg => seg.mediaType === 'metahuman'
    );

    const lastMetahuman = metahumans.reduce((acc, curr) => {
      const accEndTime = getEndTime(acc);
      const currEndTime = getEndTime(curr);

      return currEndTime > accEndTime ? curr : acc;
    }, metahumans[0]);

    function getEndTime(seg: any) {
      if (!seg) return;
      return seg.insertTime + seg.duration;
    }

    return getEndTime(lastMetahuman);
  };

  // 타이틀 Input
  const [title, setTitle] = useState({
    input: true,
    text: 'Untitled',
  });

  const editProjectName = () => {
    if (title.input === false) {
      if (!title.text) {
        window.alert('프로젝트명을 입력해주세요.');
        setTitle({
          input: false,
          text: '제목없음',
        });
        return;
      }
      changeProjectName({
        projectId: Number(projectId),
        projectName: title.text,
      })
        .then(res => {
          if (res.status !== 200) {
            console.error('Error change Project Name!!!');
          }
          // const data = res.data.body;
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  // 프로젝트 저장
  const saveProjectBtn = () => {
    setSavePopup(false);
    loading({ isLoading: true, text: t('프로젝트 저장 중입니다.'), time: 2 });
    saveProject({
      projectId: Number(projectId),
      playTime: entireDuration,
      pages: projectInfo as IPages[],
      rails: rail,
    })
      .then(() => {
        setBeforeunload(!savePopup);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 5000);
      })
      .catch(err => {
        console.log(err);        
      }).finally(() => {
        loading({ isLoading: false, text: '', time: 0 });        
      });
  };

  // 미리보기 팝업
  const [popup, setPopup] = useState(0);
  const [previewPopup, setPreviewPopup] = useState(false);
  const [previewShow, setPreviewShow] = useState(false);
  const [savePopup, setSavePopup] = useState(false);

  const confirmSaveProject = () => {
    setSavePopup(!savePopup);
    setExportShow(false);
    setPreviewPopup(false);
  };

  const previewPopupToggle = () => {
    if (!previewPopup) {
      setExportShow(false);
      setPreviewPopup(true);
      setSavePopup(false);
      setPage({
        select: false,
        value: '',
      });
      setControls(0);
    } else {
      setPreviewPopup(false);
    }
  };

  const previewShowToggle = () => {
    if (!previewShow) {
      setPreviewPopup(false);
      setPreviewShow(true);
      setSavePopup(false);
    } else {
      setPreviewShow(false);
    }
  };

  // 내보내기 팝업
  const [exportShow, setExportShow] = useState(false);
  const [extensionShow, setExtensionShow] = useState(false);
  const [exportVoiceShow, setExportVoiceShow] = useState(false);

  const exportVoicePopupToggle = () => {
    if (!exportVoiceShow) {
      setExportShow(false);
      setPreviewPopup(false);
      setExportVoiceShow(true);
    } else {
      setExportVoiceShow(false);
    }
  };

  const exportPopupToggle = () => {
    if (!exportShow) {
      setExportVoiceShow(false);
      setPreviewPopup(false);
      setExportShow(true);
      setExportPopup({
        select: false,
        extension: '',
        quality: '',
      });
    } else {
      setExportShow(false);
    }
  };

  const extensionPopupToggle = () => {
    if (!extensionShow) {
      setExportShow(false);
      setExtensionShow(true);
      setPopup(4);
    } else {
      setExtensionShow(false);
      setExportShow(true);
      setPopup(0);
    }
  };

  // 미리보기 폼
  const [page, setPage] = useState<any>({
    select: false,
    value: '',
  });

  // 내보내기 폼
  const [exportPopup, setExportPopup] = useState({
    select: false,
    extension: '',
    quality: '',
  });

  // 프로젝트 정보 (pages정보 -> recoil 저장, projectId 저장)
  const [previewNum, setPreviewNum] = useState(0);
  const [autoScript, setAutoScript] = useRecoilState(autoScriptArray);
  const [count, setCount] = useState(0);
  const [credit, setCredit] = useState(0);

  const getCreditLeftWithResData = (result: any) => {
    let totalCredit = 0;
    result?.map((item: any) => {
      totalCredit += item.credit;
    });
    return totalCredit;
  };

  const getPayCreditLeft = () => {
    getLeftCredit()
      .then(res => {
        if (res.status === 200) {
          setCredit(getCreditLeftWithResData(res?.data?.resultValue));
        }
      })
      .catch(console.error);
  };

  useEffect(() => getPayCreditLeft(), []);

  useEffect(() => {
    if (count) {
      setBeforeunload(false);
    }
  }, [projectInfo]);

  useEffect(() => {
    loading({
      isLoading: true,
      text: t('프로젝트 정보를 불러오는 중입니다.'),
      time: 2,
    });

    if (projectId) {
      doGetProjectInfo(projectId)
        .then(res => {
          const data = res.data.body;
          if (data.pages.length > 0) {
            setProjectInfo(data.pages);
            loading({ isLoading: false, text: '', time: 0 });
            setTimeout(() => {
              setCount(count + 1);
            }, 1000);
          } else {
            setTimeout(() => {
              setCount(count + 1);
            }, 1000);
          }
          setTitle({ ...title, text: data.projectName });
          setPreviewNum(data.previewNum);
          setPlayTime(data.playTime);
          loading({ isLoading: false, text: '', time: 0 });
          if (autoScript.length === 0) {
            const tmpArray = [];
            data.pages[0]?.segments[0]?.segment?.map(item => {
              if (item?.metaData?.sync) {
                tmpArray.push(...item?.metaData?.sync);
              }
            });
            setAutoScript(tmpArray);
          }
        })
        .catch(err => {
          console.log(err);
          loading({ isLoading: false, text: '', time: 0 });
        });
    } else {
      window.alert(t('프로젝트 정보가 잘못되었습니다.'));
      window.history.back();
    }
  }, [projectId, previewInfo]);

  // 미리보기
  const requestPreview = (recall: boolean) => {
    const selectedSceneDuration = segment.filter(
      seg => seg.mediaType === 'metahuman'
    )[page.value - 1].duration;

    const dividingValue = Math.ceil(selectedSceneDuration + 1) / Math.ceil(10);

    loading({
      isLoading: true,
      text: t('미리보기 대기 중입니다.'),
      text1: t('인공지능 AI가 미리보기 영상을 생성하기까지 최소 1~3분 정도 소요됩니다.'),
      time: selectedSceneDuration / dividingValue,
    });
    createPreview({
      projectId: Number(projectId),
      page: page.value,
      playTime,
      recall,
      pages: projectInfo as ISegments[],
      segmentId: page.segmentId,
    })
      .then(({ data }) => {
        const { body, code } = data;
        if (code === '200') {
          setPreviewPopup(false);
          setPreviewShow(true);
          loading({ isLoading: false, text: '', time: 0 });
          setPreviewInfo(body);
        } else if (code === '201') {
          setTimeout(() => requestPreview(true), 10000);
        } else {
          loading({ isLoading: false, text: '', time: 0 });
          alert('미리보기 중 오류가 발생하였습니다. 잠시 후 다시 시도해 주십시요.');
        }
      })
      .catch(err => {
        console.error('에러 메시지 : ' + JSON.stringify(err?.response?.data?.body));
        loading({ isLoading: false, text: '', time: 0 });
        alert('미리보기 중 오류가 발생하였습니다. 잠시 후 다시 시도해 주십시요.');
      });
  };

  // 프로젝트 저장 후 미리보기
  const saveBeforePreview = () => {
    saveProject({
      projectId: +projectId,
      playTime: entireDuration,
      pages: projectInfo as IPages[],
      rails: rail,
    })
      .then(({ data }) => {
        const { code, body } = data;

        if (code === '500') {
          getErrorMessage(body);
        } else {
          setBeforeunload(true);
          requestPreview(false);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  // 내보내기
  const exportVideo = () => {
    createVideo({
      projectId: Number(projectId),
      format: exportPopup.extension,
      display: exportPopup.quality,
      credit: Math.floor(getPlayTime() / 100) * 16,
      companySeq: getStoragePaysystemCompanySeq(),
      exportVoice: exportVoiceShow ? 'Y' : 'N',
    })
      .then(() => navigate('/my-projects'))
      .catch(err => {
        alert(t('잠시 후 다시 시도해주세요.'));
        console.log(err);
        setPopup(0);
        setExtensionShow(false);
      });
  };

  const getErrorMessage = error => {
    let message = '';
    switch (error.errorCode) {
      case 'MetaHuman':
        message = `${
          process.env.REACT_APP_LANGUAGE === 'ko' ? error.errorScreen : ''
        } ${t('장면의 메타휴먼 정보가 없습니다.')} ${
          process.env.REACT_APP_LANGUAGE === 'en' ? error.errorScreen : ''
        }`;
        break;
      case 'SpecialCharacters':
        message = `${
          process.env.REACT_APP_LANGUAGE === 'ko' ? error.errorScreen : ''
        } ${t(
          '장면의 스크립트에 특수문자만 존재하여 음성파일을 생성할 수 없습니다.'
        )} ${process.env.REACT_APP_LANGUAGE === 'en' ? error.errorScreen : ''}`;
        break;
      case 'Emojis':
        message = `${
          process.env.REACT_APP_LANGUAGE === 'ko' ? error.errorScreen : ''
        } ${t('장면의 스크립트에 이모지가 포함되어 있습니다.')} ${
          process.env.REACT_APP_LANGUAGE === 'en' ? error.errorScreen : ''
        }`;
        break;
      case 'WavFilePath':
        message = `${
          process.env.REACT_APP_LANGUAGE === 'ko' ? error.errorScreen : ''
        } ${t('장면의 음성파일 생성중 오류가 발생하였습니다.')} ${
          process.env.REACT_APP_LANGUAGE === 'en' ? error.errorScreen : ''
        }`;
        break;
      case 'TextEmojis':
        message = `${t(
          '텍스트에 이모지가 포함되어 영상파일을 생성할 수 없습니다.'
        )}`;
        break;
      default:
        message = '';
        break;
    }
    alert(message);
  };

  // 프로젝트 저장 후 내보내기
  const exportBtn = async () => {
    setIsExported(true);

    if (credit < Math.floor(getPlayTime() / 100) * 16) {
      alert(t('보유한 크레딧이 부족하여, 영상을 생성할 수 없습니다.'));
      setIsExported(false);
      return;
    }

    getMe()
      .then(() => {
        // eslint-disable-next-line no-lonely-if
        if (projectInfo) {
          const segment = projectInfo[0]?.segments[0]?.segment.filter(
            seg => seg.mediaType === 'metahuman'
          );
          let isScript = true;
          segment?.filter((item: any) => {
            if (item.title === '') {
              isScript = false;
            }
          });
          if (!isScript) {
            alert(
              t(
                '메타휴먼을 생성하지 못한 장면이 있습니다. 스크립트를 확인해주세요.'
              )
            );
            setPopup(0);
            setExtensionShow(false);
          } else {
            saveProject({
              projectId: Number(projectId),
              playTime: entireDuration,
              pages: projectInfo as IPages[],
              rails: rail,
            })
              .then(res => {
                if (res.data.code === '500') {
                  getErrorMessage(res.data.body);
                } else {
                  setBeforeunload(true);
                  exportVideo();
                }
              })
              .catch(err => {
                alert(err);
              });
          }
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setIsExported(false);
      });
  };

  function timeCal(cs: number) {
    const minute = (cs / (100 * 60)) % 60;
    const second = (cs / 100) % 60;
    const minuteCut = `0${Math.floor(minute)}`.slice(-2);
    const secondCut = `0${Math.floor(second)}`.slice(-2);
    return `${minuteCut}:${secondCut}`;
  }

  let tmpTalk = '';
  let tmpTalkSortedArray = segment ? [...segment] : [];
  segment?.map(item => {
    if (item?.metaData?.integratedId === tmpTalk) {
      tmpTalkSortedArray = tmpTalkSortedArray.filter(
        element => element.segmentId !== item.segmentId
      );
    }
    if (item?.metaData?.integratedId) {
      tmpTalk = item?.metaData?.integratedId;
    }
  });

  return (
    <div className="studio__header">
      <Title
        t={t}
        title={title}
        setTitle={setTitle}
        editProjectName={editProjectName}
      />
      <ButtonSection
        t={t}
        confirmSaveProject={confirmSaveProject}
        previewPopupToggle={previewPopupToggle}
        previewNum={previewNum}
        exportPopupToggle={exportPopupToggle}
        exportVoicePopupToggle={exportVoicePopupToggle}
      />

      {savePopup && (
        <SaveModal
          t={t}
          setSavePopup={setSavePopup}
          saveProjectBtn={saveProjectBtn}
          PreviewPopupIcon={PreviewPopupIcon}
        />
      )}

      {previewPopup && (
        <PreviewSelectModal
          previewPopup={previewPopup}
          previewNum={previewNum}
          previewPopupToggle={previewPopupToggle}
          PreviewPopupIcon={PreviewPopupIcon}
          setPage={setPage}
          page={page}
          PopupSelectBtn={PopupSelectBtn}
          t={t}
          segment={segment}
          tmpTalkSortedArray={tmpTalkSortedArray}
          msToTime={msToTime}
          requestPreview={saveBeforePreview}
        />
      )}

      {previewShow && (
        <PreviewModal
          t={t}
          previewShowToggle={previewShowToggle}
          previewInfo={previewInfo}
          exportBtn={exportPopupToggle}
        />
      )}

      {exportShow && (
        <ExportModal
          PreviewPopupIcon={PreviewPopupIcon}
          exportPopupToggle={exportPopupToggle}
          t={t}
          extensionPopupToggle={extensionPopupToggle}
        />
      )}

      {extensionShow && (
        <ExtensionPopup
          t={t}
          setExtensionShow={setExtensionShow}
          setExportPopup={setExportPopup}
          exportPopup={exportPopup}
          PopupSelectBtn={PopupSelectBtn}
          popup={popup}
          setPopup={setPopup}
          extensionPopupToggle={exportPopupToggle}
        />
      )}

      {exportVoiceShow && (
        <ExportVoiceModal
          PreviewPopupIcon={PreviewPopupIcon}
          exportVoicePopupToggle={exportVoicePopupToggle}
          setPopup={setPopup}
          t={t}
        />
      )}

      {popup === 5 && (
        <QualityPopup
          t={t}
          setExtensionShow={setExtensionShow}
          setExportPopup={setExportPopup}
          exportPopup={exportPopup}
          PopupSelectBtn={PopupSelectBtn}
          setPopup={setPopup}
        />
      )}

      {popup === 6 && (
        <TotalCredit
          isExported={isExported}
          timeCal={timeCal(getPlayTime())}
          getPlayTime={getPlayTime}
          Coin={Coin}
          t={t}
          setPopup={setPopup}
          exportBtn={exportBtn}
          ExclamationMark={ExclamationMark}
          exportVoiceShow={exportVoiceShow}
        />
      )}
    </div>
  );
};

export default SubHeader;
