import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getResourceList } from '../../../networks/source.service';
import { lang } from '../../../recoil/atoms/user';
import { changeHost } from '../../../utils/common';
import FreeBadge from '../../common/FreeBadge';
import PayBadge from '../../common/Paybadge';
import Pagination from '../../common/pagination-new';
import { enableImageResourType } from '../../../util/common';

export default function ImageTab({
  isModalActive,
  imageActive,
  projectId,
  t,
  setBackgroundInfo,
  backgroundInfo,
  addBackground,
  active,
  dragFunction,
  dragOver,
  uploadLoading,
  deleteLoading,
  Loader,
  uploadResource,
  FileUpload,
  deleteResource,
}) {
  const [category, setCategory] = useState('기본');
  const [defaultImageList, setDefaultImageList] = useState([]);
  const [purchasedImageList, setPurchasedImageList] = useState([]);
  const [myImageList, setMyImageList] = useState([]);
  const [paged, setPaged] = useState(1);
  const [parentPage, setParentPage] = useState(1);
  const postsPerPage = 20;
  const [myTotal, setMyTotal] = useState(0);
  const [purchasedTotal, setPurchasedTotal] = useState(0);
  const [defaultTotal, setDefaultTotal] = useState(0);
  const language = useRecoilValue(lang);
  const categoryArray: { category: string; length?: number }[] = [
    {
      category: '기본',
      length: defaultTotal || 0,
    },
    {
      category: '구매',
      length: purchasedTotal || 0,
    },
    {
      category: '업로드',
      length: myTotal || 0,
    },
  ];

  const typeStyle = language === 'en' ? 'text-xs' : '';

  // for getting item counts
  useEffect(() => {
    if (!isModalActive || imageActive !== 1) return;
    getResourceList({
      group: 'background',
      type: 'image',
      projectId,
      isMine: 0,
      isPurchased: 0,
      postsPerPage,
      paged: 1,
      lang: language,
    })
      .then(res => {
        const { total } = res.data.body;
        setDefaultTotal(total);
      })
      .catch(console.log);

    getResourceList({
      group: 'background',
      type: 'image',
      projectId,
      isMine: 0,
      postsPerPage,
      paged: 1,
      isPurchased: 1,
      lang: language,
    })
      .then(res => {
        const { total } = res.data.body;
        setPurchasedTotal(total);
      })
      .catch(console.log);

    getResourceList({
      group: 'background',
      type: 'image',
      projectId,
      postsPerPage,
      paged: 1,
      isMine: 1,
      lang: language,
    })
      .then(res => {
        const { total } = res.data.body;
        setMyTotal(total);
      })
      .catch(console.log);
  }, [isModalActive, imageActive]);

  useEffect(() => {
    if (!isModalActive || imageActive !== 1) return;
    switch (category) {
      case '기본':
        getResourceList({
          group: 'background',
          type: 'image',
          projectId,
          isMine: 0,
          isPurchased: 0,
          postsPerPage,
          paged,
          lang: language,
        })
          .then(res => setDefaultImageList(res.data.body.resources))
          .catch(console.log);
        break;
      case '구매':
        getResourceList({
          group: 'background',
          type: 'image',
          projectId,
          isMine: 0,
          postsPerPage,
          paged,
          isPurchased: 1,
          lang: language,
        })
          .then(res => setPurchasedImageList(res.data.body.resources))
          .catch(console.log);
        break;
      case '업로드':
        getResourceList({
          group: 'background',
          type: 'image',
          projectId,
          postsPerPage,
          paged,
          isMine: 1,
          lang: language,
        })
          .then(res => setMyImageList(res.data.body.resources))
          .catch(console.log);
        break;
      default:
        break;
    }
  }, [paged, isModalActive, category, imageActive]);

  useEffect(() => {
    if (!isModalActive || category !== '업로드' || imageActive !== 1) return;
    getResourceList({
      group: 'background',
      type: 'image',
      projectId,
      postsPerPage,
      paged,
      isMine: 1,
      lang: language,
    })
      .then(res => setMyImageList(res.data.body.resources))
      .catch(console.log);
  }, [uploadResource, deleteResource, imageActive]);

  const handleOnPage = (page: number) => {
    window.scrollTo(0, 0);
    setPaged(page);
    setParentPage(page);
  };

  return (
    <div className={imageActive === 1 ? 'img__content show' : 'img__content'}>
      <div className="button__list">
        {categoryArray.map((list, idx) => (
          <button
            key={`list-${idx}`}
            className={category === list.category ? 'active' : ''}
            onClick={() => {
              setCategory(list.category);
              setPaged(1);
            }}>
            <span className={typeStyle}>{t(list.category)}</span>
            <span className="ml-1 text-[#7b61ff]">
              {list.length > 99 ? '99+' : list.length}
            </span>
          </button>
        ))}
      </div>

      <div
        className={
          category === '기본'
            ? 'img__list list show default h-full'
            : 'img__list list default h-full'
        }>
        <ul className="h-[350px] overflow-y-auto secondary-scroll ">
          {defaultImageList &&
            defaultImageList.map((value: any, idx: number) => {
              return (
                <>
                  <li key={`value-${idx}`}>
                    <button
                      className={
                        backgroundInfo.sourceURL === value.filePath
                          ? 'active'
                          : undefined
                      }
                      onClick={() => {
                        setBackgroundInfo({
                          ...backgroundInfo,
                          sourceURL: value.filePath,
                          type: 'image',
                        });
                        addBackground(value, 'image');
                      }}>
                      <div
                        className="img__bg"
                        style={{
                          backgroundImage: `url(${changeHost(value.source)})`,
                          display: `${active === 0 ? 'none' : 'block'}`,
                        }}
                      />
                      <div className="img__bg_hov" />
                      <FreeBadge />
                      <p className="img__text">{value.title}</p>
                    </button>
                  </li>
                </>
              );
            })}
        </ul>
        {defaultImageList ? (
          <Pagination
            isSmall
            onPageChange={handleOnPage}
            totalItems={defaultTotal}
            currentPage={paged}
            parentPage={parentPage}
            itemsPerPage={postsPerPage}
          />
        ) : null}
      </div>

      <div
        className={
          category === '구매'
            ? 'img__list list show default h-full'
            : 'img__list list default h-full'
        }>
        <ul className="h-[350px] overflow-y-auto secondary-scroll">
          {purchasedImageList?.map((value: any, idx: number) => {
            return (
              <li key={`value-${idx}`}>
                <button
                  className={
                    backgroundInfo.sourceURL === value.filePath
                      ? 'active'
                      : undefined
                  }
                  onClick={() => {
                    setBackgroundInfo({
                      ...backgroundInfo,
                      sourceURL: value.filePath,
                      type: 'image',
                    });
                    addBackground(value, 'image');
                  }}>
                  <div
                    className="img__bg"
                    style={{
                      backgroundImage: `url(${changeHost(value.source)})`,
                      display: `${active === 0 ? 'none' : 'block'}`,
                    }}
                  />
                  <div className="img__bg_hov" />
                  <PayBadge />
                  <p className="img__text">{value.title}</p>
                </button>
              </li>
            );
          })}
        </ul>

        <Pagination
          isSmall
          onPageChange={handleOnPage}
          totalItems={purchasedTotal}
          currentPage={paged}
          parentPage={parentPage}
          itemsPerPage={postsPerPage}
        />
      </div>

      <div
        className={
          category === '업로드' ? 'img__list list my show' : 'img__list list my'
        }>
        <div
          className="file__upload__box"
          onDragOver={event => {
            event.preventDefault();
            event.stopPropagation();
            if (!dragOver) {
              dragFunction(event, 'over', 'image');
            }
          }}
          onDrop={event => dragFunction(event, 'drop', 'image')}
          onDragLeave={event => dragFunction(event, 'leave', 'image')}
          style={{ opacity: dragOver ? 0.5 : 1 }}>
          {(uploadLoading || deleteLoading) && (
            <div className="upload_loading">
              <Loader width={120} height={120} />
              <div className="upload-loding-text">
                {uploadLoading
                  ? '파일 업로드중입니다.'
                  : deleteLoading
                  ? '파일 삭제중입니다.'
                  : ''}
              </div>
            </div>
          )}
          <input
            type="file"
            id="file__upload__img"
            className="file__upload"
            accept={enableImageResourType.join(', ')}
            onChange={e => {
              uploadResource(e, 'image');
            }}
          />
          <label htmlFor="file__upload__img">
            <img src={FileUpload} alt="file_upload" />
            <h3>
              {t('파일 업로드')} ({t('최대')} 150MB)
            </h3>
            <p className="px-12">
              {t('브라우저를 클릭하거나 파일을 끌어다가 놓으세요.')}
            </p>
            <span className="text-xs text-[#adadad]">
              {t('가능 확장자')}: .gif, .png, .jpeg, .bmp
            </span>
          </label>
        </div>
        <ul className="h-[200px] overflow-y-auto secondary-scroll">
          {myImageList &&
            myImageList.map((i: any, idx: number) => {
              return (
                <li key={idx}>
                  <button
                    className="remove__btn card__remove__icon"
                    onClick={() => {
                      deleteResource(i.ID);
                    }}
                  />
                  <button
                    className={
                      backgroundInfo.sourceURL === i.filePath
                        ? 'active'
                        : undefined
                    }
                    onClick={() => {
                      setBackgroundInfo({
                        ...backgroundInfo,
                        sourceURL: i.filePath,
                        type: 'image',
                      });
                      addBackground(i, 'image');
                    }}>
                    <div
                      className="img__bg"
                      style={{
                        backgroundImage: `url(${changeHost(i.source)})`,
                        display: `${active === 0 ? 'none' : 'block'}`,
                      }}
                    />
                    <div className="img__bg_hov" />
                    <p className="img__text">{i.fileName}</p>
                  </button>
                </li>
              );
            })}
        </ul>
        {myImageList ? (
          <Pagination
            isSmall
            onPageChange={handleOnPage}
            totalItems={myTotal}
            currentPage={paged}
            parentPage={parentPage}
            itemsPerPage={postsPerPage}
          />
        ) : null}
      </div>
    </div>
  );
}
