import moment from 'moment';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IPaySystemSubscribeData } from '../../../networks/dto/IPaySystemDto';
import { isSignedAtom } from '../../../networks/store/member';
import { lang } from '../../../recoil/atoms/user';
import { InformModalType } from '../../../types_new/commonTypes';
import { UserInfoType } from '../../../types_new/main/service/types';
import { formatTelNumber } from '../../../utils/common';
import InformModal from '../../modals/inform-modal';
import CoinIcon from '../../svg/coin-icon';
import PencilIcon from '../../svg/pencil-icon';
import ContactModal from './contact-modal';
import PasswordModal from './payment/password-modal';
import WithdrawModal from './withdraw-modal';

const LABEL_CLASS = 'w-full flex flex-col font-medium';
const INPUT_CLASS =
  'w-full px-5 py-4 rounded-lg border border-[#e9e9e9] bg-lightGray';

type Props = {
  myInfo: UserInfoType;
  subscribeInfo: IPaySystemSubscribeData;
  // paysubscribe: String;
  paycreditleft: String;
};

export default function MyInfo({
  myInfo: { userName, userEmail, userPhone, lastLoginTime, userRegistered },
  subscribeInfo,
  // paysubscribe,
  paycreditleft,
}: Props) {
  const [modal, setModal] = useState({
    withdraw: false,
    password: false,
    contact: false,
  });
  const [informModal, setInformModal] = useState<InformModalType>({
    isOpen: false,
    title: '',
    message: '',
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const language = useRecoilValue(lang);
  const setIsLoggedIn = useSetRecoilState(isSignedAtom);

  const closeInformModal = () => {
    if (informModal.title === '회원 탈퇴 완료') {
      setIsLoggedIn(false);
      navigate('/');
    } else {
      setInformModal(prev => ({
        ...prev,
        isOpen: false,
      }));
      passwordInputRef.current?.focus();
    }
  };

  const closeModal = (key: string) => {
    setModal(prev => ({
      ...prev,
      [key]: false,
    }));
  };

  const getSubscribeLimitDate = (limitdt: string): string => {
    const result = moment(limitdt).diff(moment(), 'days');
    console.log('getSubscribeLimitDate:' + result);
    return result.toString();
  };

  return (
    <>
      <ContactModal
        setInformModal={setInformModal}
        isOpen={modal.contact}
        closeModal={() => closeModal('contact')}
      />
      <PasswordModal
        setInformModal={setInformModal}
        isOpen={modal.password}
        closeModal={() => closeModal('password')}
      />
      <WithdrawModal
        passwordInputRef={passwordInputRef}
        setInformModal={setInformModal}
        isOpen={modal.withdraw}
        closeModal={() => closeModal('withdraw')}
      />
      <InformModal
        isOpen={informModal.isOpen}
        title={informModal.title}
        message={informModal.message}
        closeModal={closeInformModal}
      />
      <div className="max-w-2xl text-darkSecondary w-full sm:bg-white rounded-lg px-5 sm:px-10 md:px-14 lg:px-16 xl:px-20 2xl:px-24 sm:py-10 md:py-12 lg:py-14 xl:py-16 2xl:py-[70px] sm:shadow-xl">
        <div className="flex flex-col space-y-4 text-darkTertiary text-sm 2xl:text-base">
          <label className={LABEL_CLASS} htmlFor="userName">
            <span className="mb-2">{t('이름')}</span>
            <input
              className={INPUT_CLASS}
              id="userName"
              type="text"
              disabled
              readOnly
              value={userName}
            />
          </label>
          <label className={LABEL_CLASS} htmlFor="userEmail">
            <span className="mb-2">{t('이메일')}</span>
            <input
              className={INPUT_CLASS}
              id="userEmail"
              type="email"
              disabled
              readOnly
              value={userEmail}
            />
          </label>
          <label className={LABEL_CLASS} htmlFor="password">
            <span className="mb-2">{t('비밀번호')}</span>
            <div className="relative">
              <input
                className={INPUT_CLASS}
                disabled
                readOnly
                id="password"
                name="password"
                type="text"
                value="***********"
              />
              <button
                onClick={() =>
                  setModal(prev => ({
                    ...prev,
                    password: true,
                  }))
                }
                className="absolute right-5 bottom-1/2 translate-y-1/2 flex items-center space-x-1 group text-[#666] hover:text-primary">
                <PencilIcon />
                <span className="text-xs sm:text-sm font-normal">
                  {t('변경')}
                </span>
              </button>
            </div>
          </label>
          <label className={LABEL_CLASS} htmlFor="userPhone">
            <span className="mb-2">{t('연락처')}</span>
            <div className="relative">
              <input
                className={INPUT_CLASS}
                readOnly
                disabled
                id="userPhone"
                type="text"
                value={formatTelNumber(userPhone)}
              />
              <button
                onClick={() =>
                  setModal(prev => ({
                    ...prev,
                    contact: true,
                  }))
                }
                className="absolute right-5 bottom-1/2 translate-y-1/2 flex items-center space-x-1 group text-[#666] hover:text-primary">
                <PencilIcon />
                <span className="text-xs sm:text-sm font-normal">
                  {t('변경')}
                </span>
              </button>
            </div>
          </label>
          <div className={LABEL_CLASS}>
            <div className="w-full flex items-center justify-between">
              <span>{t('멤버십')}</span>
              <Link to="/membership">
                <button className="text-primary text-xs font-normal hover:underline underline-offset-2">
                  {t('요금제 더 알아보기')}
                </button>
              </Link>
            </div>
            {/* TODO: add actual data when getMe api is ready */}
            <div className="mt-2 rounded-lg border border-[#E9E9E9] bg-[#F8F7FF] px-6 py-4 font-normal">
            {subscribeInfo?.itemName ? (
              <>
                {t('회원님은 현재')}{' '}
                <strong className="font-semibold text-primary">
                  {t(`${subscribeInfo?.itemName || ''}`)}
                </strong>{' '}
                {t('입니다.')}
                <br />
                {language === 'ko' && (
                  <MemberShipCardko
                    value={getSubscribeLimitDate(subscribeInfo?.limitDtTo)}
                  />
                )}
                {language !== 'ko' && (
                  <MemberShipCarden
                    value={getSubscribeLimitDate(subscribeInfo?.limitDtTo)}
                  />
                )}
              </>
            ) : (
              t('구독 정보가 없습니다.')
            )}
            </div>
          </div>
          <div className={LABEL_CLASS}>
            {t('크레딧 현황')}
            <div className="w-full flex items-center justify-between mt-2 rounded-lg bg-white border border-[#E9E9E9] px-6 py-4 font-normal">
              <span className="font-medium text-[22px]">C {paycreditleft}</span>
              <Link
                className="flex items-center space-x-1 group"
                to="/membership">
                <span className="font-medium group-hover:text-primary duration-150">
                  {t('구매')}
                </span>
                <CoinIcon />
              </Link>
            </div>
            <span className="text-xs text-primary font-normal mt-2.5">
              {t('사용 기록은 크레딧 내역에서 확인할 수 있습니다.')}
            </span>
          </div>
          <div className={LABEL_CLASS}>
            {t('활동상태')}
            {/* TODO: add actual data when getMe api is ready */}
            <div className="flex flex-col mt-2 rounded-lg border border-[#E9E9E9] bg-[#F8F7FF] px-6 py-4 font-normal">
              <span>
                {t('최초 가입일')}: {userRegistered}
              </span>
              <span>
                {t('마지막 접속일')}: {lastLoginTime}
              </span>
            </div>
          </div>
          <button
            onClick={() =>
              setModal(prev => ({
                ...prev,
                withdraw: true,
              }))
            }
            className="w-fit mx-auto pt-3 underline underline-offset-2 text-[#696969] hover:text-rose-400 duration-150">
            {t('회원탈퇴')}
          </button>
        </div>
      </div>
    </>
  );
}

function MemberShipCarden({ value }: { value: string }) {
  const { t } = useTranslation();
  return (
    <>
      <strong className="font-semibold text-primary">{value}</strong> is left
      until the end of the subscription.
    </>
  );
}

function MemberShipCardko({ value }: { value: string }) {
  // const { t } = useTranslation();
  return (
    <>
      구독 종료일까지{' '}
      <strong className="font-semibold text-primary">{value}일</strong>
      {' 남았습니다.'}
    </>
  );
}
