/* eslint-disable */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import '../../css/reset.css';
import '../../css/style.css';
import FileUpload from '../../images/common/file_upload.png';
import '../../locale/i18n';
import {
  addResource,
  delResource,
  getResourceList,
} from '../../networks/source.service';
import { block } from '../../store/block';
import {
  additionalTarget,
  centiSecond,
  markerPos,
  rails,
  setSegmentsInfo,
  pages as storedPages,
  segmentsItem as storedSegmentItem,
} from '../../store/pages';
import { checkFileSize, isEnableResourceType } from '../../util/common';
import VideoModal from '../VideoModal';
import StoreButton from '../common/StoreButton';
import Loader from '../lottie/Loader';
import ImageTab from './clipart/ImageTab';
import SoundTab from './clipart/SoundTab';
import VideoTab from './clipart/VideoTab';

const ClipartModal = ({ active, setActive, disabled }: any) => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const markerX = useRecoilValue(markerPos);
  const setCs = useSetRecoilState(centiSecond);
  const [pages, setPages] = useRecoilState(storedPages);
  /**
   * 1 : image
   * 2 : video
   * 3 : audio
   */
  const [imageActive, setImageActive] = useState(1);
  const [ismy, setIsmy] = useState(1);
  const [buttonActive, setButtonActive] = useState<any>(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [resourceList, setResourceList] = useState([]);
  const [audioList, setAudioList] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const setSegmentsItems = useSetRecoilState(storedSegmentItem);
  const addTarget = useRecoilValue(additionalTarget);
  const [railArray, setRailArray] = useRecoilState(rails);
  const [selectBlock, setSelectBlock] = useRecoilState(block);
  const [modal, setModal] = useState<{ open: boolean; url: string }>({
    open: false,
    url: '',
  });

  const openModal = (url: string) => {
    setModal({ open: true, url });
  };

  const insertTime = useMemo(() => {
    return pages[0]?.segments[0]?.segment[selectBlock.groupIndex]?.insertTime;
  }, [markerX, pages[0]?.segments[0]?.segment[0]?.insertTime]);

  const selectedBlockDuration = useMemo(() => {
    return pages[0]?.segments[0]?.segment[selectBlock.groupIndex]?.duration;
  }, [markerX]);

  const uniqueId = useMemo(() => {
    return pages[0]?.segments[0]?.segment[selectBlock.groupIndex]?.indexKey;
  }, [markerX, pages[0]?.segments[0]?.segment[0]?.insertTime]);

  const getInsertTime = () => {
    let insertT = 0;
    const tmpImage = pages[0]?.segments[0]?.image?.filter(
      item => item.railIndex === 0
    );
    const tmpTitle = pages[0]?.segments[0]?.title?.filter(
      item => item.railIndex === 0
    );
    const tmpSegment = pages[0]?.segments[0]?.segment?.filter(
      item =>
        (item.mediaType === 'video' || item.mediaType === 'audio') &&
        item.railIndex === 0
    );
    const tmpArray = [...tmpImage, ...tmpTitle, ...tmpSegment];

    let maxInsertTime = -1;
    let maxInsertTimeId = -1;
    tmpArray.map(item => {
      if (maxInsertTime <= item.insertTime) {
        maxInsertTime = item.insertTime;
        maxInsertTimeId = item.segmentId || item.imageId || item.titleId;
      }
    });
    tmpArray.map(item => {
      if (
        item.segmentId === maxInsertTimeId ||
        item.imageId === maxInsertTimeId ||
        item.titleId === maxInsertTimeId
      ) {
        insertT = item.insertTime + item.duration;
      }
    });
    return Math.floor(insertT);
  };

  // 클립 추가 (이미지)
  const addclip = (args: any) => {
    const tmpPage = JSON.parse(JSON.stringify(pages));
    let duration = 200;
    let insertT = 0;
    let railIdx = 0;

    if (addTarget.name === 'marker') {
      const targetMeta = tmpPage[0]?.segments[0]?.segment?.filter(
        item =>
          item?.insertTime <= addTarget?.insertT &&
          item?.insertTime + item?.duration >= addTarget?.insertT
      )[0];

      const targetArray = [
        ...tmpPage[0]?.segments[0]?.title,
        ...tmpPage[0]?.segments[0]?.image,
        ...tmpPage[0]?.segments[0]?.segment?.filter(
          item => item?.mediaType === 'video' || item?.mediaType === 'audio'
        ),
      ]?.filter(item => {
        return (
          item?.insertTime + item?.duration - targetMeta?.insertTime > 0 &&
          item?.insertTime - targetMeta?.insertTime < targetMeta?.duration
        );
      });
      let railIndex = 0;
      targetArray?.map(item => {
        if (item?.railIndex > railIndex) {
          railIndex = item?.railIndex;
        }
      });

      duration = targetMeta ? targetMeta?.duration : 200;
      insertT = targetMeta ? targetMeta?.insertTime : getInsertTime();
      railIdx = targetArray.length === 0 ? 0 : railIndex + 1;
    } else {
      const targetArray = tmpPage[0]?.segments[0]?.segment?.filter(item => {
        return (
          item.insertTime + item.duration - addTarget?.insertT?.insertTime >
            0 &&
          item.insertTime - addTarget?.insertT?.insertTime <
            addTarget?.insertT?.duration
        );
      });

      const maxArray = targetArray.length
        ? targetArray?.reduce((pre, cur) => {
            return pre.insertTime > cur.insertTime ? pre : cur;
          })
        : [];

      const targetMHArray = [
        ...tmpPage[0]?.segments[0]?.image,
        ...tmpPage[0]?.segments[0]?.segment?.filter(
          item => item.mediaType === 'video' || item.mediaType === 'audio'
        ),
      ]?.filter(item => {
        return (
          item.insertTime + item.duration - maxArray.insertTime > 0 &&
          item.insertTime - maxArray.insertTime < maxArray.duration
        );
      });

      let railIndex = 0;
      targetMHArray?.map(item => {
        if (item.railIndex > railIndex) {
          railIndex = item.railIndex;
        }
      });

      if (!selectedBlockDuration) {
        duration = 200;
      } else {
        duration = selectedBlockDuration;
      }
      if (insertTime === undefined) {
        insertT = 0;
      } else {
        insertT = insertTime;
      }
      railIdx = targetMHArray.length === 0 ? 0 : railIndex + 1;
    }

    if (Object.keys(selectBlock).length) {
      if (selectBlock.segmentsGroup === 'image') {
        tmpPage[0].segments[0].image[selectBlock.groupIndex] = {
          ...tmpPage[0].segments[0].image[selectBlock.groupIndex],
          imageUrl: args.source,
          imagePath: args.filePath,
          metaData: args.metaData,
          indexKey: uniqueId,
        };
        setPages(tmpPage);
      }
    } else {
      const id = Date.now();
      setSegmentsInfo(pages, setPages, {
        pagesIndex: 0,
        image: {
          imageArray: [
            ...tmpPage[0].segments[0].image,
            {
              imageId: id,
              imageUrl: args.source,
              imagePath: args.filePath,
              rank: 0,
              posX: 0,
              posY: 0,
              width: 30,
              height: 30,
              insertTime: insertT,
              duration: duration,
              metaData: args.metaData,
              indexKey: uniqueId,
              rotate: 0,
              fadeIn: 0,
              fadeOut: 0,
              zIndex: 0,
              railIndex: railIdx,
            },
          ],
        },
      });
      if (railArray?.filter(item => item.type === 'clip')?.length === railIdx) {
        setRailArray([...railArray, { type: 'clip' }]);
      }
      setSegmentsItems({ id: id, type: 'image' });
    }
    setSelectBlock({
      segmentsGroup: 'image',
      groupIndex: tmpPage[0]?.segments[0]?.image?.length || 0,
    });

    setCs(insertT);
  };

  // 새 이미지 추가
  const addNewElement = () => {
    setButtonActive(0);
    setSelectBlock({});
  };

  const addclipEtc = (args: any) => {
    const tmpPage = JSON.parse(JSON.stringify(pages));

    let insertT = 0;
    let railIdx = 0;

    if (addTarget.name === 'marker') {
      const targetMeta = tmpPage[0]?.segments[0]?.segment?.filter(
        item =>
          item?.insertTime <= addTarget?.insertT &&
          item?.insertTime + item?.duration >= addTarget?.insertT &&
          item.mediaType === 'metahuman'
      )[0];

      const targetArray = [
        ...tmpPage[0]?.segments[0]?.image,
        ...tmpPage[0]?.segments[0]?.title,
        ...tmpPage[0]?.segments[0]?.segment?.filter(
          item => item?.mediaType === 'video' || item?.mediaType === 'audio'
        ),
      ]?.filter(item => {
        return (
          item?.insertTime + item?.duration - targetMeta?.insertTime > 0 &&
          item?.insertTime - targetMeta?.insertTime < targetMeta?.duration
        );
      });
      let railIndex = 0;
      targetArray?.map(item => {
        if (item.railIndex > railIndex) {
          railIndex = item.railIndex;
        }
      });
      insertT = targetMeta ? targetMeta?.insertTime : getInsertTime();
      railIdx = targetArray?.length === 0 ? 0 : railIndex + 1;
    } else {
      const targetArray = tmpPage[0]?.segments[0]?.segment?.filter(item => {
        return (
          item?.insertTime + item?.duration - addTarget?.insertT?.insertTime >
            0 &&
          item?.insertTime - addTarget?.insertT?.insertTime <
            addTarget?.insertT?.duration &&
          item?.mediaType === 'metahuman'
        );
      });
      const maxArray = targetArray.length
        ? targetArray?.reduce((pre, cur) => {
            return pre?.insertTime > cur?.insertTime ? pre : cur;
          })
        : [];

      const targetMHArray = [
        ...tmpPage[0]?.segments[0]?.image,
        ...tmpPage[0]?.segments[0]?.segment?.filter(
          item => item?.mediaType === 'video' || item?.mediaType === 'audio'
        ),
      ]?.filter(item => {
        return (
          item?.insertTime + item?.duration - maxArray?.insertTime > 0 &&
          item?.insertTime - maxArray?.insertTime < maxArray?.duration
        );
      });

      let railIndex = 0;
      targetMHArray?.map(item => {
        if (item.railIndex > railIndex) {
          railIndex = item?.railIndex;
        }
      });

      if (insertTime === undefined) {
        insertT = 0;
      } else {
        insertT = insertTime;
      }

      railIdx = targetMHArray?.length === 0 ? 0 : railIndex + 1;
    }

    setCs(insertT);

    if (Object.keys(selectBlock).length) {
      if (selectBlock.segmentsGroup === 'segment') {
        const minute = args.lengthFormatted?.split(':')[0];
        const second = args.lengthFormatted?.split(':')[1];

        tmpPage[0].segments[0].segment[selectBlock.groupIndex] = {
          ...tmpPage[0].segments[0].segment[selectBlock.groupIndex],
          mediaType: args.mediaType,
          fileName: args.fileName,
          filePath: args.filePath,
          previewImage: args.previewImage,
          source: args.source,
          duration: Math.floor((Number(minute) * 60 + Number(second)) * 100),
          startTime: 0,
          endTime: Math.floor((Number(minute) * 60 + Number(second)) * 100),
          metaData: args.metaData,
          indexKey: uniqueId,
        };
        setPages(tmpPage);
      }
    } else {
      const minute = args.lengthFormatted?.split(':')[0];
      const second = args.lengthFormatted?.split(':')[1];
      const id = Date.now();

      setSegmentsInfo(pages, setPages, {
        pagesIndex: 0,
        segment: {
          segmentArray: [
            ...tmpPage[0].segments[0].segment,
            {
              segmentId: id,
              mediaType: args.mediaType,
              contentId: id,
              fileId: id,
              fileName: args.fileName,
              filePath: args.filePath,
              previewImage: args.previewImage,
              wavFilePath: '',
              source: args.source,
              title: '',
              videoSize: '',
              rank: 0,
              insertTime: insertT,
              duration: Math.floor(
                (Number(minute) * 60 + Number(second)) * 100
              ),
              startTime: 0,
              endTime:
                insertT +
                Math.floor((Number(minute) * 60 + Number(second)) * 100),
              metaData: args.metaData,
              indexKey: uniqueId,
              fadeIn: 0,
              fadeOut: 0,
              width: 30,
              height: 30,
              posX: 0,
              posY: 0,
              chromakey: '',
              mute: 'N',
              volume: 1.0,
              transitionType: '',
              transitionTime: '',
              zIndex: 0,
              railIndex: railIdx,
            },
          ],
        },
      });
      if (railArray?.filter(item => item.type === 'clip')?.length === railIdx) {
        setRailArray([...railArray, { type: 'clip' }]);
      }
      setSegmentsItems({ id: id, type: args.mediaType });
      setSelectBlock({
        segmentsGroup: 'segment',
        groupIndex: tmpPage[0]?.segments[0]?.segment?.length || 0,
      });
    }
  };

  // 꾸미기 수정
  useEffect(() => {
    if (Object.keys(selectBlock).length !== 0 && pages) {
      const page = pages[0];
      if (selectBlock?.segmentsGroup === 'image') {
        setActive(3);
        setImageActive(1);
        setChangeClipart({
          ...changeClipart,
          width:
            page?.segments[0][selectBlock.segmentsGroup][selectBlock.groupIndex]
              ?.width,
          height:
            page?.segments[0][selectBlock.segmentsGroup][selectBlock.groupIndex]
              ?.height,
          posX: page?.segments[0][selectBlock.segmentsGroup][
            selectBlock.groupIndex
          ]?.posX,
          posY: page?.segments[0][selectBlock.segmentsGroup][
            selectBlock.groupIndex
          ]?.posY,
        });
      } else if (
        selectBlock?.segmentsGroup === 'segment' &&
        page?.segments[0]?.segment[selectBlock?.groupIndex]?.mediaType ===
          'video'
      ) {
        setActive(3);
        setImageActive(2);
        setChangeClipart({
          ...changeClipart,
          width:
            page?.segments[0][selectBlock.segmentsGroup][selectBlock.groupIndex]
              ?.width,
          height:
            page?.segments[0][selectBlock.segmentsGroup][selectBlock.groupIndex]
              ?.height,
          posX: page?.segments[0][selectBlock.segmentsGroup][
            selectBlock.groupIndex
          ]?.posX,
          posY: page?.segments[0][selectBlock.segmentsGroup][
            selectBlock.groupIndex
          ]?.posY,
        });
      } else if (
        selectBlock?.segmentsGroup === 'segment' &&
        page?.segments[0]?.segment[selectBlock?.groupIndex]?.mediaType ===
          'audio'
      ) {
        setActive(3);
        setIsmy(5);
        setImageActive(3);
        setButtonActive(
          page?.segments[0]?.segment[selectBlock?.groupIndex]?.source
        );
      }
    } else {
      setButtonActive(0);
    }
  }, [selectBlock]);

  const [changeClipart, setChangeClipart] = useState<any>({
    width: '',
    height: '',
    posX: '',
    posY: '',
  });

  const changeCliparts = (e: any) => {
    const tmpPage = JSON.parse(JSON.stringify(pages));
    if (
      e.target.value !== '-' &&
      e.target.value !== '+' &&
      isNaN(e.target.value)
    ) {
      return false;
    }

    if (e.target.value === 'e') {
      return false;
    }

    if (e.target.name === 'width') {
      tmpPage[0].segments[0][selectBlock.segmentsGroup][
        selectBlock.groupIndex
      ] = {
        ...tmpPage[0].segments[0][selectBlock.segmentsGroup][
          selectBlock.groupIndex
        ],
        [e.target.name]: e.target.value,
        height: e.target.value,
      };
      setPages(tmpPage);
      setChangeClipart({
        ...changeClipart,
        height: e.target.value,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === 'height') {
      tmpPage[0].segments[0][selectBlock.segmentsGroup][
        selectBlock.groupIndex
      ] = {
        ...tmpPage[0].segments[0][selectBlock.segmentsGroup][
          selectBlock.groupIndex
        ],
        [e.target.name]: e.target.value,
        width: e.target.value,
      };
      setPages(tmpPage);
      setChangeClipart({
        ...changeClipart,
        width: e.target.value,
        [e.target.name]: e.target.value,
      });
    } else {
      tmpPage[0].segments[0][selectBlock.segmentsGroup][
        selectBlock.groupIndex
      ] = {
        ...tmpPage[0].segments[0][selectBlock.segmentsGroup][
          selectBlock.groupIndex
        ],
        [e.target.name]: e.target.value,
      };
      setPages(tmpPage);
      setChangeClipart({ ...changeClipart, [e.target.name]: e.target.value });
    }
  };

  // 리소스 업로드
  const uploadResource = (e: any, type: string) => {
    const fd = new FormData();
    Array.from(e.target.files).map((file: any) => {
      // console.log('[ClipartdModal] uploadResource -> file.type:', file.type);
      if (!isEnableResourceType(file.type)) {
        alert('지원하지 않는 파일 형식입니다.');
        return;
      }
      if (checkFileSize(file)) {
        fd.append('resource[]', file);
      } else {
        alert(t('최대 업로드 사이즈를 초과 하였습니다.'));
        return;
      }
    });
    fd.append('group', 'clip');
    fd.append('type', type);

    setUploadLoading(true);
    addResource(fd)
      .then(res => {
        const data = res.data.body;
        console.log('리소스 업로드 ====>', data);
        if (res.data.code === '501') {
          if (type === 'image') {
            alert(t('이미지 파일만 업로드 가능합니다.'));
          } else if (type === 'video') {
            alert(t('동영상 파일만 업로드 가능합니다.'));
          } else if (type === 'audio') {
            alert(t('오디오 파일만 업로드 가능합니다.'));
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setUploadLoading(false));
  };

  // 리소스 삭제
  const deleteResource = (id: number) => {
    setDeleteLoading(true);
    delResource({ resourceId: id })
      .then(res => {
        const data = res.data.body;
        console.log('리소스 삭제 ====>', data);
      })
      .catch(err => console.log(err))
      .finally(() => setDeleteLoading(false));
  };

  const [mouseover, setMouseover] = useState<any>(false);

  useEffect(() => {
    if (Object.keys(selectBlock).length !== 0) {
      if (
        pages[0]?.segments[0][selectBlock.segmentsGroup][selectBlock.groupIndex]
          ?.previewType === 'image'
      ) {
        const data =
          pages[0]?.segments[0][selectBlock.segmentsGroup][
            selectBlock.groupIndex
          ];
        setChangeClipart({
          ...changeClipart,
          width: data.width,
          height: data.height,
          posX: data.posX,
          posY: data.posY,
        });
      } else if (
        pages[0]?.segments[0][selectBlock.segmentsGroup][selectBlock.groupIndex]
          ?.mediaType === 'video'
      ) {
        const data =
          pages[0]?.segments[0][selectBlock.segmentsGroup][
            selectBlock.groupIndex
          ];
        setChangeClipart({
          ...changeClipart,
          width: data.width,
          height: data.height,
          posX: data.posX,
          posY: data.posY,
        });
      }
    }
  }, [pages]);

  useEffect(() => {
    if (Object.keys(selectBlock).length !== 0) {
      if (
        pages[0]?.segments[0][selectBlock.segmentsGroup][selectBlock.groupIndex]
          ?.mediaType === 'video'
      ) {
        const data =
          pages[0]?.segments[0][selectBlock.segmentsGroup][
            selectBlock.groupIndex
          ];
        setChangeClipart({
          ...changeClipart,
          width: data.width,
          height: data.height,
          posX: data.posX,
          posY: data.posY,
        });
      }
    }
  }, [pages]);

  const [dragOver, setDragOver] = useState(false);

  const dragFunction = useCallback((event, action, type) => {
    event.preventDefault();
    event.stopPropagation();
    if (action === 'drop') {
      if (type === 'image') {
        if (event?.dataTransfer?.files[0]?.type?.substr(0, 5) !== 'image') {
          alert(t('파일 형식을 확인해주세요'));
          setDragOver(false);
          return;
        }
      } else if (type === 'video') {
        const name = event?.dataTransfer?.files[0]?.type?.slice(-3);
        if (
          !(
            name === 'mp4' ||
            name === 'avi' ||
            name === 'mov' ||
            name === 'mxf' ||
            name === 'ime' ||
            name === 'wmv'
          )
        ) {
          console.log(event?.dataTransfer?.files[0]?.type?.slice(-3));
          alert(t('파일 형식을 확인해주세요'));
          setDragOver(false);
          return;
        }
      } else if (type === 'audio') {
        const name = event?.dataTransfer?.files[0]?.type?.substr(6);
        if (!(name === 'mp3' || name === 'wav' || name === 'mpeg')) {
          alert(t('파일 형식을 확인해주세요'));
          setDragOver(false);
          return;
        }
      }

      if (!checkFileSize(event?.dataTransfer?.files[0])) {
        alert(t('최대 업로드 사이즈를 초과 하였습니다.'));
        setDragOver(false);
        return;
      }
    }
    if (action === 'over') setDragOver(true);
    if (action === 'leave') setDragOver(false);
    if (action === 'drop') {
      setDragOver(false);
      const fd = new FormData();
      Array.from(event.dataTransfer.files).map((file: any) => {
        fd.append('resource[]', file);
      });

      fd.append('group', 'clip');
      fd.append('type', type);

      addResource(fd).then(res => {
        const data = res.data.body;
        console.log('리소스 업로드 ====>', data);
        getResourceList({
          group: 'clip',
          type,
          projectId: +projectId,
          isMine: 1,
          lang: process.env.REACT_APP_LANGUAGE,
        })
          .then(res => {
            const data = res.data.body.resources;
            setResourceList(data);
            if (type === 'audio') {
              let tmpArray = [];
              data.map((item: any) => {
                tmpArray.push(new Audio(item.source));
              });
              setAudioList(tmpArray);
            }
          })
          .catch(err => {
            setResourceList([]);
            alert(err);
          });
      });
    }
  }, []);

  return (
    <>
      <VideoModal
        isOpen={modal.open}
        closeModal={() => setModal(prev => ({ ...prev, open: false }))}
        videoURL={modal.url}
      />
      <li>
        <button
          disabled={disabled}
          className={active == 3 ? 'active' : 'disabled:cursor-not-allowed'}
          onClick={() => {
            setActive(3);
            setSelectBlock({});
          }}>
          <i className="style__deco__icon" />
          <span>{t('꾸미기')}</span>
        </button>

        <div className="deco h-full max-h-[600px]">
          <div className="title">
            <StoreButton t={t} category={'꾸미기'} />
            <button
              className="close__btn close__gray__icon"
              onClick={() => {
                setActive(0);
              }}
            />
          </div>
          <div className="tab__list">
            <button
              className={imageActive == 1 ? 'active' : undefined}
              onClick={() => {
                setImageActive(1);
                setIsmy(1);
                addNewElement();
                setSelectBlock({});
              }}>
              {t('이미지')}
            </button>
            <button
              className={imageActive == 2 ? 'active' : undefined}
              onClick={() => {
                setImageActive(2);
                setIsmy(3);
                addNewElement();
                setSelectBlock({});
              }}>
              {t('영상')}
            </button>
            <button
              className={imageActive == 3 ? 'active' : undefined}
              onClick={() => {
                setImageActive(3);
                setIsmy(5);
                addNewElement();
                setSelectBlock({});
              }}>
              {t('효과음')}
            </button>
          </div>
          {
            ((Object.keys(selectBlock).length !== 0 &&
              selectBlock.segmentsGroup === 'image') ||
              selectBlock.segmentsGroup === 'segment') &&
              pages[0]?.segments[0]?.segment[selectBlock?.groupIndex]
                ?.mediaType !== 'audio' && (
                // 꾸미기 편집시 기존 버튼, 미리보기 이미지 삭제 후 편집구간 생성(+ '새 이미지, 영상, 효과음 추가' 버튼)
                <div className="meta-edit-cont">
                  <div className="edit-box border-20">
                    <h3>{t('크기')}</h3>
                    <div className="edit-input-box justify-center space-x-10">
                      <div className="edit-input-cont flex-col">
                        <span>{t('가로')}</span>
                        <input
                          name="width"
                          type="number"
                          value={parseFloat(changeClipart.width).toFixed(0)}
                          onChange={changeCliparts}
                        />
                      </div>
                      <div className="edit-input-cont flex-col">
                        <span>{t('세로')}</span>
                        <input
                          name="height"
                          type="number"
                          value={parseFloat(changeClipart.height).toFixed(0)}
                          onChange={changeCliparts}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="edit-box">
                    <h3>{t('위치')}</h3>
                    <div className="edit-input-box justify-center space-x-10">
                      <div className="edit-input-cont flex-col">
                        <span>{t('상하')}</span>
                        <input
                          name="posY"
                          value={changeClipart.posY}
                          type="number"
                          onChange={changeCliparts}
                        />
                      </div>
                      <div className="edit-input-cont flex-col">
                        <span>{t('좌우')}</span>
                        <input
                          name="posX"
                          value={changeClipart.posX}
                          type="number"
                          onChange={changeCliparts}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            // 꾸미기 편집시 기존 버튼, 미리보기 이미지 삭제 후 편집구간 생성(+ '새 이미지, 영상, 효과음 추가' 버튼)
          }

          {(Object.keys(selectBlock).length === 0 ||
            pages[0]?.segments[0]?.segment[selectBlock?.groupIndex]
              ?.mediaType === 'audio' ||
            (selectBlock.segmentsGroup !== 'image' &&
              selectBlock.segmentsGroup !== 'segment')) && (
            <div className="content">
              <ImageTab
                isModalActive={active === 3}
                imageActive={imageActive}
                t={t}
                addclip={addclip}
                active={active}
                uploadLoading={uploadLoading}
                deleteLoading={deleteLoading}
                Loader={Loader}
                dragFunction={dragFunction}
                dragOver={dragOver}
                uploadResource={uploadResource}
                FileUpload={FileUpload}
                deleteResource={deleteResource}
                projectId={projectId}
              />
              <VideoTab
                isModalActive={active === 3}
                imageActive={imageActive}
                t={t}
                addclipEtc={addclipEtc}
                active={active}
                uploadLoading={uploadLoading}
                deleteLoading={deleteLoading}
                Loader={Loader}
                dragFunction={dragFunction}
                dragOver={dragOver}
                uploadResource={uploadResource}
                FileUpload={FileUpload}
                deleteResource={deleteResource}
                projectId={projectId}
                openModal={openModal}
                buttonActive={buttonActive}
              />
              <SoundTab
                isModalActive={active === 3}
                imageActive={imageActive}
                t={t}
                addclipEtc={addclipEtc}
                uploadLoading={uploadLoading}
                deleteLoading={deleteLoading}
                Loader={Loader}
                dragFunction={dragFunction}
                dragOver={dragOver}
                uploadResource={uploadResource}
                FileUpload={FileUpload}
                deleteResource={deleteResource}
                projectId={projectId}
                buttonActive={buttonActive}
                setButtonActive={setButtonActive}
                active={active}
              />
            </div>
          )}
          {Object.keys(selectBlock).length !== 0 && imageActive === 1 ? (
            <button
              className="edit-new fixed top-[590px] right-32"
              onClick={addNewElement}>
              {t('새 이미지 추가')}
            </button>
          ) : Object.keys(selectBlock).length !== 0 && imageActive === 2 ? (
            <button
              className="edit-new fixed top-[590px] right-32"
              onClick={addNewElement}>
              {t('새 영상 추가')}
            </button>
          ) : null}
        </div>
      </li>
    </>
  );
};

export default ClipartModal;
