import styled from 'styled-components';

const LoadingWrapper = styled.div<{
  isLoading: boolean;
  time: number;
}>`
  /* @import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css); */
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999999999999999999999999999999999;
  background: rgba(0, 0, 0, 0.7);
  display: ${props => (props.isLoading ? 'block;' : 'none')};
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
    line-height: 1;
  }
  .prograssbar_wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
  }
  .prograssbar_wrap.fixed {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transform: none;
    background-color: rgba(0, 0, 0, 0.68);
  }
  .prograssbar_wrap .prograssbar_txt {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 24px;
    letter-spacing: -0.03em;
  }
  .prograssbar_wrap.fixed .prograssbar_txt {
    color: #fff;
  }
  .prograssbar_container {
    position: relative;
    width: 500px;
    height: 12px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.35);
    overflow: hidden;
  }
  .prograssbar_wrap.fixed .prograssbar_container {
    background-color: rgba(255, 255, 255, 0.35);
  }
  .prograssbar_wrap .prograssbar {
    position: absolute;
    width: 20%;
    height: 100%;
    background-color: #7b61ff;
    top: 0;
    left: 0;
    display: block;
    border-radius: 12px;
  }
  .prograssbar_wrap .prograssbar.anim {
    /* transition: width 0.3s ease; */
    animation: ${props =>
      'prograssbar_anim ' + props.time + 's ease-out forwards'};
    /* animation: prograssbar_anim 2s ease-out forwards; */
  }
  @keyframes prograssbar_anim {
    0% {
      width: 0%;
    }
    100% {
      width: 98%;
    }
  }
`;

const Loading = (props: any) => {
  return (
    <LoadingWrapper
      isLoading={props.onLoading.isLoading}
      time={props.onLoading.time}>
      <div className="prograssbar_wrap fixed">
        {props.onLoading.text1 !== '' && <p className="prograssbar_txt">{props.onLoading.text1}</p>}
        <p className="prograssbar_txt">{props.onLoading.text}</p>
        <div className="prograssbar_container">
          <div className="prograssbar anim"></div>
        </div>
      </div>
    </LoadingWrapper>
  );
};
export default Loading;
