/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import '../../css/reset.css';
import '../../css/style.css';
import { isProd } from '../../env';
import useEntireDuration from '../../hooks/use-entire-duration';
import '../../locale/i18n';
import { doGetProjectInfo } from '../../networks/project.service';
import { addResource } from '../../networks/source.service';
import {
  createPDFpage,
  createPPTpage,
  saveProject,
} from '../../services/project';
import { userFields } from '../../store/auth';
import { block } from '../../store/block';
import { TOGGLE_LOADING } from '../../store/common/loading';
import {
  rails,
  selectTransition,
  pages as storedPages,
} from '../../store/pages';
import { IPages } from '../../store/pages/types';
import Page from './Page';
import PdfUploadModal from './PdfUploadModal';
import PptUploadModal from './PptUploadModal';

const Pages = ({ setMetahuman, setTransform }: any) => {
  const entireDuration = useEntireDuration();
  const { t } = useTranslation();
  const loading = useSetRecoilState(TOGGLE_LOADING);
  const [pages, setPages] = useRecoilState<any>(storedPages);
  const setBlocks = useSetRecoilState(block);
  const me = useRecoilValue(userFields);
  const { projectId } = useParams();
  const [previewNum, setPreviewNum] = useState(0);
  const setTransition = useSetRecoilState(selectTransition);
  const [rail, setRail] = useRecoilState(rails);

  let bgArray = [];

  useEffect(() => {
    doGetProjectInfo(projectId)
      .then(res => {
        const data = res.data.body;
        localStorage.removeItem('saved');
        setPreviewNum(previewNum);
        if (data.rails === '') {
          setRail([
            { type: 'metahuman' },
            { type: 'clip' },
            { type: 'background' },
          ]);
        } else {
          setRail(data.rails);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, [projectId]);

  // PDF 업로드
  const uploadPDF = (e: any) => {
    if (!me) {
      alert('사용자 정보가 없습니다.');
      return;
    }

    //PDF 내기타 파입로 업로드
    const fd2 = new FormData();
    Array.from(e.target.files).map((file: any) => {
      fd2.append('resource[]', file);
      console.log(file);
    });

    fd2.append('group', 'etc');
    fd2.append('type', 'pdf');

    addResource(fd2)
      .then(res => {
        if (res.data.code === '501') {
          alert(t('PDF 파일만 업로드 가능합니다.'));
        } else if (res.data.code === '200') {
          const filePath = res.data.body.file_path;
          loading({
            isLoading: true,
            text: t('PDF 업로드중 입니다.'),
            time: e.target.files[0].size / 1024 / 100,
          });
          saveProject({
            projectId: Number(projectId),
            playTime: entireDuration,
            pages: pages as IPages[],
            rails: rail,
          })
            .then(res => {
              const data = res.data.body;
              if (res.data.code === '200') {
                console.log('프로젝트 저장 ====> ', data);

                const fd = new FormData();
                Array.from(e.target.files).map((file: any) => {
                  fd.append('file', file);
                });
                fd.append('projectId', String(projectId));
                fd.append('userId', String(me.userId));
                fd.append('filePath', filePath);

                createPDFpage(fd)
                  .then(res => {
                    const data = res.data.body;
                    console.log('pdf ====> ', data);
                    if (res.data.code === '200') {
                      console.log('PDF upload success ====> ', data);
                      setPages(data.pages);
                      loading({ isLoading: false, text: '', time: 0 });
                    } else {
                      alert(t('PDF 업로드를 실패했습니다.'));
                      console.log('PDF Upload 실패');
                      loading({ isLoading: false, text: '', time: 0 });
                    }
                  })
                  .catch(err => {
                    alert(t('PDF 업로드를 실패했습니다.'));

                    console.error(err);
                    loading({ isLoading: false, text: '', time: 0 });
                  });
              } else if (res.data.body.errorCode === 'WavFilePath') {
                alert(t('모든 메타휴먼의 스크립트를 작성해주세요.'));
                loading({ isLoading: false, text: '', time: 0 });
              } else {
                alert(t('PDF 업로드를 실패했습니다.'));
                console.log('프로젝트 저장 실패');
              }
            })
            .catch(err => {
              alert(t('PDF 업로드를 실패했습니다.'));
              console.error(err);
              loading({ isLoading: false, text: '', time: 0 });
            });
        }
      })
      .catch(err => console.log(err));
  };

  // PPT 업로드
  const uploadPPT = (e: any) => {
    //ppt 내기타 파입로 업로드
    const fd2 = new FormData();
    Array.from(e.target.files).map((file: any) => {
      fd2.append('resource[]', file);
      console.log(file);
    });

    fd2.append('group', 'etc');
    fd2.append('type', 'ppt');

    addResource(fd2)
      .then(res => {
        if (res.data.code === '501') {
          alert(t('PPT 파일만 업로드 가능합니다.'));
        } else if (res.data.code === '200') {
          const filePath = res.data.body.file_path;
          loading({
            isLoading: true,
            text: t('PPT 업로드 중입니다.'),
            time: e.target.files[0].size / 1024 / 100,
          });
          saveProject({
            projectId: Number(projectId),
            playTime: entireDuration,
            pages: pages as IPages[],
            rails: rail,
          })
            .then(res => {
              const data = res.data.body;
              if (res.data.code === '200') {
                console.log('프로젝트 저장 ====> ', data);

                const fd = new FormData();
                Array.from(e.target.files).map((file: any) => {
                  fd.append('file', file);
                  fd.append('userId', String(me.userId));
                });

                fd.append('projectId', String(projectId));
                fd.append('filePath', filePath);

                createPPTpage(fd).then(res => {
                  const data = res.data.body;
                  if (res.data.code === '200') {
                    loading({ isLoading: false, text: '', time: 0 });
                    console.log('PPT upload success ====> ', data);
                    setPages(data.pages);
                  }else if (res.data.code === '500' && typeof res.data.body === 'string') {                    
                    alert('PPT 내에 변환이 지원되지 않는 컨텐츠가 포함되어 있습니다. [' + res.data.body + ']');
                    loading({ isLoading: false, text: '', time: 0 });
                  } else {
                    alert(data.message);
                    // window.location.reload();
                    loading({ isLoading: false, text: '', time: 0 });
                  }
                });
              } else if (res.data.body.errorCode === 'WavFilePath') {
                alert(t('모든 메타휴먼의 스크립트를 작성해주세요.'));
                loading({ isLoading: false, text: '', time: 0 });
              } else {
                alert(t('PPT 업로드를 실패했습니다.'));
                loading({ isLoading: false, text: '', time: 0 });
                console.error('프로젝트 저장 실패');
              }
            })
            .catch(err => {
              alert(t('PPT 업로드를 실패했습니다.'));
              loading({ isLoading: false, text: '', time: 0 });
              console.log(err);
            });
        }
      })
      .catch(err => {
        alert(t('PPT 업로드를 실패했습니다.'));
        loading({ isLoading: false, text: '', time: 0 });
        console.log(err);
      });
  };
  const metahumanLength = pages[0]?.segments[0]?.segment?.filter(
    item => item.mediaType === 'metahuman'
  ).length;

  // let filteredPagesArray = segment ? [...segment] : [];

  const [filteredPagesArray, setFilteredPagesArray] = useState([]);
  useEffect(() => {
    let tmpTalk = '';
    const tmpArray = [];
    pages[0]?.segments[0]?.segment?.map(item => {
      if (item?.mediaType === 'metahuman') {
        if (
          item?.metaData?.integratedId !== tmpTalk ||
          item?.metaData?.integratedId === undefined
        ) {
          tmpArray.push(item);
        }
        if (item?.metaData?.integratedId) {
          tmpTalk = item?.metaData?.integratedId;
        }
      }
    });
    setFilteredPagesArray(tmpArray);
    bgArray = [];
  }, [pages]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <article className="page__list">
          <div className="title">
            <h2
              onClick={() => {
                console.log(pages[0].segments[0], '장면');
              }}>
              Scenes
            </h2>
            <span
              className="total"
              data-total-number={
                pages.length > 0
                  ? pages[0]?.segments[0]?.segment?.filter((val: any) => {
                      if (val.mediaType == 'metahuman') return val;
                    }).length < 10
                    ? '0' +
                      pages[0]?.segments[0]?.segment?.filter((val: any) => {
                        if (val.mediaType == 'metahuman') return val;
                      }).length
                    : pages[0]?.segments[0]?.segment?.filter((val: any) => {
                        if (val.mediaType == 'metahuman') return val;
                      }).length
                  : 0
              }>
              total
            </span>
          </div>
          <ul className="list">
            {filteredPagesArray
              ?.filter(item => true)
              .sort((a, b) => a.insertTime - b.insertTime)
              ?.map((metaVal: any, idx: number) => {
                if (metaVal.mediaType == 'metahuman') {
                  if (1) {
                    const insertTime = metaVal?.insertTime;
                    const endTime = metaVal?.insertTime + metaVal?.duration;
                    const background = pages[0]?.segments[0]['background'];

                    for (let i = 0; i < background?.length; i++) {
                      let bgElement = background[i];

                      if (
                        insertTime <= bgElement?.insertTime &&
                        endTime > bgElement?.insertTime
                      ) {
                        if (bgElement?.color) {
                          bgArray.push({
                            type: 'color',
                            value: bgElement?.color,
                          });
                        } else if (bgElement?.previewImage) {
                          bgArray.push({
                            type: 'previewImage',
                            value: bgElement?.previewImage,
                          });
                        } else {
                          bgArray.push({
                            type: '',
                            value: null,
                          });
                        }
                        break;
                      }
                      if (i == background?.length - 1) {
                        bgArray.push({
                          type: '',
                          value: null,
                        });
                        break;
                      }
                    }
                  }
                  return (
                    <React.Fragment key={idx}>
                      <Page idx={idx} metaVal={metaVal} />
                      {pages[0]?.segments[0]?.segment
                        ?.filter((val: any) => val.mediaType == 'metahuman')
                        .indexOf(metaVal) !==
                        metahumanLength - 1 && (
                        <div
                          // fade값 있으면 active 추가
                          className={`transitionIcon ${
                            metaVal.transitionType !== '' ? 'active' : ''
                          }`}
                          onClick={() => {
                            setTransform(true);
                            setBlocks({
                              segmentsGroup: 'segment',
                              groupIndex: idx,
                            });
                            setTransition({
                              id: metaVal.segmentId,
                              type: 'metahuman',
                            });
                          }}
                        />
                      )}
                    </React.Fragment>
                  );
                }
              })}
            <li>
              <div className="card card_add__box">
                <div className="add__box">
                  <i className="plus__icon" />
                  <span>{t('장면 추가')}</span>
                  <div className="addPage__btn__list w-32">
                    <button
                      onClick={() => {
                        setMetahuman(true);
                      }}>
                      {t('빈 장면 추가')}
                    </button>

                    {/* TODO: remove isProd condition when pdf upload function is ready */}
                    {!isProd && <PdfUploadModal uploadPDF={uploadPDF} />}

                    {/* <PdfUploadModal uploadPDF={uploadPDF} /> */}
                    <PptUploadModal uploadPPT={uploadPPT} />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </article>
      </div>
    </>
  );
};

export default Pages;
