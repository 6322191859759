import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { paymentFilterArray } from '../../../../arrays';
import {
  IPaySystemSubscribeData,
  IPaymentUsageReqData,
} from '../../../../networks/dto/IPaySystemDto';
import { getPaymentUsageList } from '../../../../networks/paysystem.service';
import InfoFilterSection from '../info-filter-section';
import NoListCard from '../no-list-card';
import MobilePaymentCard from './mobile-payment-card';
import PaymentCard from './payment-card';
import PaymentTable from './payment-table';

type Props = {
  subscribeInfo: IPaySystemSubscribeData;
  paycreditleft: string;
};

export default function PaymentInfo({ subscribeInfo, paycreditleft }: Props) {
  const [filter, setFilter] = useState(paymentFilterArray[0]);

  // TODO: change it to actual payment data with api function
  // const paymentData = paymentDummyArr;
  const [paymentList, setPaymentList] = useState([]);
  const todayStr = moment().format('YYYYMMDD');
  const pageSize = 10;
  const [paged, setPaged] = useState(1);
  const maxPage = useRef(0);
  const [total, setTotal] = useState(0);
  const isNoResult = !total;
  useEffect(() => {
    const args: IPaymentUsageReqData = {
      page: paged - 1,
      size: pageSize,
      order: 'desc',
      from: '20230101',
      to: todayStr,
    };
    getPaymentUsageList(args)
      .then(res => {
        if (res.data.resultStatus === 200) {
          setPaymentList(res.data.resultValue.content);
          maxPage.current = res.data.resultValue.totalPages;
          setTotal(res.data.resultValue.totalElements);
        } else {
          console.log('error');
        }
      })
      .catch(console.error);

    return () => {};
  }, [paged, filter]);

  return (
    <section className="text-darkSecondary max-w-[1278px] w-full px-5">
      <PaymentCard
        subscribeInfo={subscribeInfo}
        paycreditleft={paycreditleft}
      />
      <InfoFilterSection
        total={paymentList.length}
        filterArray={paymentFilterArray}
        setFilter={setFilter}
        filter={filter}
      />
      {paymentList.map((data, idx) => (
        <MobilePaymentCard key={`mobilePaymentInfo-${idx}`} data={data} />
      ))}
      {isNoResult ? (
        <NoListCard text="결제" />
      ) : (
        <PaymentTable paymentData={paymentList} />
      )}
    </section>
  );
}
