/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getResourceList } from '../../../networks/source.service';
import { lang } from '../../../recoil/atoms/user';
import FreeBadge from '../../common/FreeBadge';
import PayBadge from '../../common/Paybadge';
import Pagination from '../../common/pagination-new';
import { enableAudioResourType } from '../../../util/common';

export default function SoundTab({
  isModalActive,
  imageActive,
  t,
  addclipEtc,
  uploadLoading,
  deleteLoading,
  Loader,
  dragFunction,
  dragOver,
  uploadResource,
  FileUpload,
  deleteResource,
  projectId,
  buttonActive,
  setButtonActive,
  active,
}) {
  const [category, setCategory] = useState('기본');
  const [myTotal, setMyTotal] = useState(0);
  const [purchasedTotal, setPurchasedTotal] = useState(0);
  const [audioActive, setAudioActive] = useState<any>('');
  const [defaultAudioList, setDefaultAudioList] = useState([]);
  const [purchasedAudioList, setPurchasedAudioList] = useState([]);
  const [myAudioList, setMyAudioList] = useState([]);
  const [paged, setPaged] = useState(1);
  const [parentPage, setParentPage] = useState(1);
  const postsPerPage = 20;
  const [defaultTotal, setDefaultTotal] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const language = useRecoilValue(lang);
  const categoryArray: { category: string; length?: number }[] = [
    {
      category: '기본',
      length: defaultTotal || 0,
    },
    {
      category: '구매',
      length: purchasedTotal || 0,
    },
    {
      category: '업로드',
      length: myTotal || 0,
    },
  ];

  const typeStyle = language === 'en' ? 'text-xs' : '';

  // for getting item counts
  useEffect(() => {
    if (!isModalActive || imageActive !== 3) return;
    getResourceList({
      group: 'clip',
      type: 'audio',
      projectId,
      isMine: 0,
      isPurchased: 0,
      postsPerPage,
      paged: 1,
      lang: language,
    })
      .then(res => {
        const { total } = res.data.body;
        setDefaultTotal(total);
      })
      .catch(console.log);

    getResourceList({
      group: 'clip',
      type: 'audio',
      projectId,
      isMine: 0,
      postsPerPage,
      paged: 1,
      isPurchased: 1,
      lang: language,
    })
      .then(res => {
        const { total } = res.data.body;
        setPurchasedTotal(total);
      })
      .catch(console.log);

    getResourceList({
      group: 'clip',
      type: 'audio',
      projectId,
      postsPerPage,
      paged: 1,
      isMine: 1,
      lang: language,
    })
      .then(res => {
        const { total } = res.data.body;
        setMyTotal(total);
      })
      .catch(console.log);
  }, [isModalActive, imageActive]);

  useEffect(() => {
    if (!isModalActive || imageActive !== 3) return;
    switch (category) {
      case '기본':
        getResourceList({
          group: 'clip',
          type: 'audio',
          projectId,
          isMine: 0,
          isPurchased: 0,
          postsPerPage,
          paged,
          lang: language,
        })
          .then(res => setDefaultAudioList(res.data.body.resources))
          .catch(console.log);
        break;
      case '구매':
        getResourceList({
          group: 'clip',
          type: 'audio',
          projectId,
          isMine: 0,
          postsPerPage,
          paged,
          isPurchased: 1,
          lang: language,
        })
          .then(res => setPurchasedAudioList(res.data.body.resources))
          .catch(console.log);
        break;
      case '업로드':
        getResourceList({
          group: 'clip',
          type: 'audio',
          projectId,
          postsPerPage,
          paged,
          isMine: 1,
          lang: language,
        })
          .then(res => setMyAudioList(res.data.body.resources))
          .catch(console.log);
        break;
      default:
        break;
    }
  }, [paged, isModalActive, category, imageActive]);

  useEffect(() => {
    if (!isModalActive || category !== '업로드' || imageActive !== 3) return;
    getResourceList({
      group: 'clip',
      type: 'audio',
      projectId,
      postsPerPage,
      paged,
      isMine: 1,
      lang: language,
    })
      .then(res => setMyAudioList(res.data.body.resources))
      .catch(console.log);
  }, [uploadResource, deleteResource, imageActive]);

  const handleOnPage = (page: number) => {
    window.scrollTo(0, 0);
    setPaged(page);
    setParentPage(page);
  };

  useEffect(() => {
    if (!audioActive) return;

    audioRef.current.pause();
    setAudioActive(null);
    setIsPlaying(false);
  }, [category, imageActive, paged, active]);

  const handleAudioClick = useCallback(
    (audioURL: string) => {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
        setAudioActive(null);
      }
      if (audioRef.current.src === encodeURI(audioURL)) return;

      audioRef.current = new Audio(audioURL);
      audioRef.current.play();
      setIsPlaying(true);
      setAudioActive(audioURL);

      audioRef.current.onended = () => {
        setIsPlaying(false);
        setAudioActive(null);
      };
    },
    [isPlaying, audioActive]
  );

  return (
    <div
      className={
        imageActive === 3 ? 'soundEffect__content show' : 'soundEffect__content'
      }>
      <audio ref={audioRef} />
      <div className="button__list">
        {categoryArray.map((list, idx) => (
          <button
            key={`list-${idx}`}
            className={category === list.category ? 'active' : undefined}
            onClick={() => {
              setCategory(list.category);
              setPaged(1);
            }}>
            <span className={typeStyle}>{t(list.category)}</span>
            <span className="ml-1 text-[#7b61ff]">
              {list.length > 99 ? '99+' : list.length}
            </span>
          </button>
        ))}
      </div>

      <div
        className={
          category === '기본'
            ? 'soundEffect__list list list_st2 show default h-full'
            : 'soundEffect__list list list_st2 default h-full'
        }>
        <ul className="h-[350px]">
          {defaultAudioList?.map((value: any, idx: number) => (
            <li key={`defaultValue-${idx}`}>
              <FreeBadge />
              {/* <!-- play__btn에 active 추가하면 스탑 아이콘 나옵니다. --> */}
              <button
                className={`play__btn w-[22%] ${
                  audioActive === value.source && 'active'
                }`}>
                <i
                  onClick={() => handleAudioClick(value.source)}
                  className="play__purple__icon"
                />
                <span>{value.lengthFormatted}</span>
              </button>
              <button
                className={buttonActive === value.source ? 'active' : undefined}
                onClick={() => {
                  setButtonActive(value.source);
                  addclipEtc({
                    mediaType: 'audio',
                    ...value,
                  });
                }}>
                <span className="w-2/3">{value.title}</span>
              </button>
            </li>
          ))}
        </ul>
        {defaultTotal && (
          <Pagination
            isSmall
            onPageChange={handleOnPage}
            totalItems={purchasedTotal}
            currentPage={paged}
            parentPage={parentPage}
            itemsPerPage={postsPerPage}
          />
        )}
      </div>

      <div
        className={
          category === '구매'
            ? 'soundEffect__list list list_st2 show default h-full'
            : 'soundEffect__list list list_st2 default h-full'
        }>
        <ul className="h-[350px]">
          {purchasedAudioList?.map((value: any, idx: number) => (
            <li key={`purchased-${idx}`}>
              <PayBadge />
              <button
                className={`play__btn w-[22%] ${
                  audioActive === value.source && 'active'
                }`}>
                <i
                  onClick={() => handleAudioClick(value.source)}
                  className="play__purple__icon"
                />
                <span>{value.lengthFormatted}</span>
              </button>
              <button
                className={buttonActive === value.source ? 'active' : undefined}
                onClick={() => {
                  setButtonActive(value.source);
                  addclipEtc({
                    mediaType: 'audio',
                    ...value,
                  });
                }}>
                <span className="w-2/3">{value.title}</span>
              </button>
            </li>
          ))}
        </ul>
        {purchasedTotal && (
          <Pagination
            isSmall
            onPageChange={handleOnPage}
            totalItems={purchasedTotal}
            currentPage={paged}
            parentPage={parentPage}
            itemsPerPage={postsPerPage}
          />
        )}
      </div>
      <div
        className={
          category === '업로드'
            ? 'soundEffect__list list list_st2 my show'
            : 'soundEffect__list list list_st2 my'
        }>
        <div
          className="file__upload__box"
          onDragOver={event => {
            event.preventDefault();
            event.stopPropagation();
            if (!dragOver) {
              dragFunction(event, 'over', 'audio');
            }
          }}
          onDrop={event => dragFunction(event, 'drop', 'audio')}
          onDragLeave={event => dragFunction(event, 'leave', 'audio')}
          style={{ opacity: dragOver ? 0.5 : 1 }}>
          {(uploadLoading || deleteLoading) && (
            <div className="upload_loading">
              <Loader width={120} height={120} />
              <div className="upload-loding-text">
                {uploadLoading
                  ? '파일 업로드중입니다.'
                  : deleteLoading
                  ? '파일 삭제중입니다.'
                  : ''}
              </div>
            </div>
          )}
          <input
            type="file"
            id="file__upload__soundEffect"
            className="file__upload"
            accept={enableAudioResourType.join(', ')}
            onChange={e => {
              uploadResource(e, 'audio');
            }}
          />
          <label htmlFor="file__upload__soundEffect">
            <img src={FileUpload} alt="file_upload" />
            <h3>
              {t('파일 업로드')} ({t('최대')} 150MB)
            </h3>
            <p className="px-12">
              {t('브라우저를 클릭하거나 파일을 끌어다가 놓으세요.')}
            </p>
            <span className="text-xs text-[#adadad]">
              {t('가능 확장자')}: .mp3, .wav
            </span>
          </label>
        </div>
        <ul className="h-[200px] overflow-y-auto secondary-scroll">
          {myAudioList?.map((value: any, idx: number) => (
            <li className="group" key={`uploaded-${idx}`}>
              {/* <!-- play__btn에 active 추가하면 스탑 아이콘 나옵니다. --> */}
              <button
                className={`play__btn ${audioActive === idx && 'active'}`}>
                <i
                  onClick={() => {
                    if (audioActive === idx) {
                      myAudioList[idx].pause();
                      myAudioList[idx].currentTime = 0;
                      setAudioActive('');
                    } else if (audioActive !== '') {
                      if (!myAudioList) {
                        return;
                      }
                      myAudioList?.map((item: any) => {
                        item.pause();
                        item.currentTime = 0;
                      });
                      myAudioList[idx].play();
                      setAudioActive(idx);
                      myAudioList[idx].onended = () => {
                        setAudioActive('');
                      };
                    } else {
                      setAudioActive(idx);
                      myAudioList[idx].play();
                      myAudioList[idx].onended = () => {
                        setAudioActive('');
                      };
                    }
                  }}
                  className="play__purple__icon"
                />

                <i
                  className="remove__gray__icon hidden group-hover:block"
                  onClick={e => {
                    e.preventDefault();
                    deleteResource(value.ID);
                  }}
                />
                <span className="group-hover:hidden">
                  {value.lengthFormatted}
                </span>
              </button>
              <button
                className={buttonActive === value.source ? 'active' : undefined}
                onClick={() => {
                  setButtonActive(value.source);
                  addclipEtc({
                    mediaType: 'audio',
                    ...value,
                  });
                }}>
                <span>{value.fileName}</span>
              </button>
            </li>
          ))}
        </ul>
        {myTotal && (
          <Pagination
            isSmall
            onPageChange={handleOnPage}
            totalItems={myTotal}
            currentPage={paged}
            parentPage={parentPage}
            itemsPerPage={postsPerPage}
          />
        )}
      </div>
    </div>
  );
}
