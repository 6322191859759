import { useState } from 'react';
import { videoArr1, videoArr2 } from '../../../arrays';
import VideoModal from '../../VideoModal';
import VideoCard from './video-card';
import { getMainDir } from '../../../util/common';

export default function VideoFlowSection() {
  const [modal, setModal] = useState({ isOpen: false, url: '' });

  const openVideoModal = (url: string) => {
    setModal({
      isOpen: true,
      url,
    });
  };

  return (
    <>
      <VideoModal
        isOpen={modal.isOpen}
        closeModal={() => setModal(prev => ({ ...prev, isOpen: false }))}
        videoURL={modal.url}
      />
      <section className="w-screen mx-auto mt-20 lg:mt-24 2xl:mt-28">
        <ul className="flex animate-leftFlow">
          {videoArr1.map(({ imgPath, videoPath }, idx) => (
            <li
              className="cursor-pointer group"
              onClick={() => openVideoModal(getMainDir(videoPath))}
              key={`video-${idx}`}>
              <VideoCard imgPath={getMainDir(imgPath)} />
            </li>
          ))}
        </ul>
        <ul className="flex animate-rightFlow">
          {videoArr2.map(({ imgPath, videoPath }, idx) => (
            <li
              className="cursor-pointer group"
              onClick={() => openVideoModal(getMainDir(videoPath))}
              key={`video2-${idx}`}>
              <VideoCard imgPath={getMainDir(imgPath)} />
            </li>
          ))}
        </ul>
      </section>
    </>
  );
}
