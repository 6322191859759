import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ExclamationIcon from '../../components/svg/exclamation-icon';
import { getMainDir } from '../../util/common';

export default function SignupPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="sm:max-w-lg max-sm:h-full w-full py-12 px-5 sm:px-8 rounded-lg bg-black/50 backdrop-blur-sm flex flex-col items-center justify-center">
        <div className="flex flex-col space-y-3 md:space-y-5 xl:space-y-7 text-center text-sm md:text-base xl:text-lg">
          <h1 className="text-xl xl:text-2xl leading-9">
            {t('회원가입을 환영합니다.')}
          </h1>
          <p className="font-light">
            {t('플루닛 스튜디오에 가입해주시는 분들께는')}
            <br />
            {t('5천 크레딧을 무료로 제공해드립니다.')}
          </p>
        </div>
        <button
          onClick={() => navigate('/signup/steps/terms')}
          className="relative h-48 sm:h-56 min-w-[200px] w-1/2 md:w-3/5 mx-auto cursor-pointer rounded-md bg-primary hover:bg-primary/80 mt-5 duration-200 group">
          <i className="absolute right-5 top-5 w-10 sm:w-12 max-sm:animate-float sm:group-hover:animate-float duration-100">
            <img src={getMainDir('/img/rocket.png')} alt="rocket" />
          </i>
          <div className="absolute left-5 bottom-5 flex flex-col space-y-1 font-light text-base xl:text-lg text-left">
            <span>{t('누구나')}</span>
            <span>{t('가입 가능한')}</span>
            <span className="font-medium">{t('개인회원')}</span>
          </div>
        </button>
        <div className="flex flex-col space-y-3 items-center mt-10">
          <div className="flex items-center space-x-1 text-xs font-medium">
            <div className="flex items-center space-x-1.5 text-grayText">
              <ExclamationIcon />
              <span>{t('이미 회원이신가요?')}</span>
            </div>
            <Link to="/login">
              <button className="text-primary underline underline-offset-2 hover:text-primary/80 duration-100">
                {t('로그인 하러 가기')}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
